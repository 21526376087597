import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { eachDayOfInterval, format, parseISO, subDays } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { UserContext } from "../../../context/user";
import { getURLs } from "../../../urlConfig";
import { AppDataContext } from "../../../context/appData";
import FilterDropdownWithLabel from "../../../components/FilterDropdownWithLabel";
import AdminDateInput from "../../../components/AdminPageComponents/AdminDateInput";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AdminUsageReports = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    state: { appData },
  } = useContext(AppDataContext);
  const [allBots, setAllBots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    startDate: format(subDays(new Date(), 7), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    bot: "Math",
  });
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [openFiltersDropdown, setOpenFiltersDropdown] = useState(null);

  const handleApplyClick = () => {
    fetchUsageData();
  };

  const handleFilterSelect = (filterType, value) => {
    setAppliedFilters((prev) => ({ ...prev, [filterType]: value }));
    setOpenFiltersDropdown(null);
  };

  // Function to toggle dropdown visibility
  const openFilters = (filterType) => {
    setOpenFiltersDropdown(
      openFiltersDropdown === filterType ? null : filterType
    );
  };

  const fetchUsageData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(getURLs("all-search-history"), {
        params: {
          page: 1,
          startDate: appliedFilters.startDate,
          endDate: appliedFilters.endDate,
          bot: appliedFilters?.bot,
          disablePagination: true,
        },
        withCredentials: true,
        headers: {
          "auth-token": userInfo?.authToken,
        },
      });

      const searchHistory = response.data?.searchesHistory;
      const startDate = parseISO(appliedFilters.startDate);
      const endDate = parseISO(appliedFilters.endDate);
      const isSingleDay =
        format(startDate, "yyyy-MM-dd") === format(endDate, "yyyy-MM-dd");

      // Generate a continuous list of dates
      const allDates = eachDayOfInterval({
        start: startDate,
        end: endDate,
      }).map((date) => format(date, "dd-MM-yyyy"));

      // Aggregate search data
      const aggregatedData = searchHistory.reduce((acc, search) => {
        let key;

        if (isSingleDay) {
          key = format(new Date(search.searchTime), "HH:mm"); // Group by time for a single day
        } else {
          key = format(new Date(search.searchTime), "dd-MM-yyyy"); // Group by date for multiple days
        }

        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      // Ensure every date in the range is present in the x-axis
      const labels = isSingleDay ? Object.keys(aggregatedData) : allDates;

      const dataValues = labels.map((date) => aggregatedData[date] || 0);

      // Update Chart Data
      setChartData({
        labels,
        datasets: [
          {
            label: "Searches",
            data: dataValues,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            tension: 0.4,
          },
        ],
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search history:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && Object.keys(userInfo || {}).length) {
      fetchUsageData();
    }
  }, [userInfo]);

  useEffect(() => {
    if (appData?.dashboardTutorsData?.availableTutors) {
      setAllBots(
        appData?.dashboardTutorsData?.availableTutors?.map(
          (botInfo) => botInfo.tutorName
        )
      );
    }
  }, [appData]);

  return (
    <div className="p-4">
      <h2 className="font-urbanist font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        Search Usage Reports
      </h2>

      {/* Filters */}
      <div className="flex items-center gap-4 flex-wrap mb-4">
        <AdminDateInput
          selectedDate={appliedFilters?.startDate}
          applyFilter={handleFilterSelect}
          filterFor={"startDate"}
          label={"Start Date"}
        />
        <AdminDateInput
          selectedDate={appliedFilters?.endDate}
          applyFilter={handleFilterSelect}
          filterFor={"endDate"}
          label={"End Date"}
        />
        <FilterDropdownWithLabel
          label={"Bots"}
          filterFor={"bot"}
          filterValues={allBots}
          handleFilterSelect={handleFilterSelect}
          dropdownOpen={openFiltersDropdown === "bot"}
          openDropdown={openFilters}
          appliedFilterName={appliedFilters?.bot}
        />

        <button
          className="bg-black2 dark:bg-white disabled:bg-black/20 disabled:dark:bg-gray-200 px-4 py-1 text-white dark:text-black2 font-inter font-medium text-sm text-center rounded-lg"
          onClick={handleApplyClick}
          //   disabled={loading}
        >
          Apply
        </button>
      </div>

      {/* Chart */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
        {loading ? (
          <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
            Loading...
          </p>
        ) : chartData.labels.length > 0 ? (
          <div className="min-h-[250px] sm:min-h-[450px]">
            <Line
              data={chartData}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: { legend: { display: true } },
                scales: {
                  y: {
                    min: 0, // Ensures the y-axis starts at zero
                  },
                },
              }}
            />
          </div>
        ) : (
          <p className="text-center text-gray-500">No data available</p>
        )}
      </div>
    </div>
  );
};

export default AdminUsageReports;
