import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CameraIcon,
  MicrophoneIcon,
  PaperAirplaneIcon,
  StopCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import "../Hero.css";
import { UserContext } from "../../../context/user";
import { getBotURLs, getURLs } from "../../../urlConfig";
import { mathjaxConfig } from "../../../utils/staticData";
import ShowPreviousChats from "../ShowPreviousChats/ShowPreviousChats";
import PromotionCardsContainer from "../PromotionCardsContainer/PromotionCardsContainer";
import CustomModal from "../../CustomModal/CustomModal";
import {
  getBotIds,
  getTextChatIds,
  getVisionChatIds,
  setBotIds,
  setChatIds,
} from "../../../utils/network-requests";
import Loader from "../../Loader";
import { PromptRunningContext } from "../../../context/promptContext";
import { AppDataContext } from "../../../context/appData";
import TrialOverModel from "../../../modal/TrialOverModel";

const CoursesHero = (props) => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);
  // userinfo to check if user is logged in or not
  const {
    state: { userInfo, searchesLeftForUser, userBotInfo },
    updateSearchesLeftForUser,
    updateUserBotIdInfo,
    updateUserChatIdInfo,
  } = useContext(UserContext);

  // prompt context
  const {
    state: { promptRunning },
    updatePromptRunning,
    updateStopPrompt,
  } = useContext(PromptRunningContext);

  // router
  const navigate = useNavigate();

  // initializing state
  const [initialisingBot, setInitialisingBot] = useState(false);
  const [chatIdApiRunning, setChatIdApiRunning] = useState(false);
  const [visionChatIdApiRunning, setVisionChatIdApiRunning] = useState(false);

  const [failedToInitialise, setFailedToInitialise] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);

  // search query state
  const [searchQuery, setSearchQuery] = useState(
    props?.searchValue ? props?.searchValue : ""
  );
  const [initialSearch, setInitialSearch] = useState(
    props?.searchValue ? props?.searchValue : ""
  );
  const [inputTextError, setInputTextError] = useState(false);
  // loading state
  const [loading, setLoading] = useState(true);
  // search error state
  const [searchError, setSearchError] = useState({ show: false, message: "" });
  // modal open state
  const [openTrialOverModal, setOpenTrialOverModal] = useState(false);
  // current chats state
  const [currentChats, setCurrentChats] = useState([]);
  // files state
  const [files, setFiles] = useState([]);
  // api controller state
  const apiAbortController = useRef(null);
  // promotion images state
  const [promotionImages, setPromotionImages] = useState([]);
  // promotion video state
  const [promotionVideos, setPromotionVideos] = useState([]);
  // search made through text box
  const [isSearchMade, setIsSearchMade] = useState(false);
  const scrollableRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const audioRef = useRef(null);

  useEffect(() => {
    if (mediaRecorder.current) {
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(chunks.current, { type: "audio/wav" });
        setAudioBlob(audioBlob);
      };

      return () => {
        if (mediaRecorder.current) {
          mediaRecorder.current.ondataavailable = null;
          mediaRecorder.current.onstop = null;
        }
      };
    }
  }, [mediaRecorder.current]);

  const deleteSavedRecording = () => {
    setAudioBlob(null);
  };

  const checkAndRequestPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });
      if (permissionStatus.state === "granted") {
        return true;
      } else if (permissionStatus.state === "prompt") {
        // Request permission
        const permissionResult = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        if (permissionResult) {
          permissionResult.getTracks().forEach((track) => track.stop());
          return true;
        }
      } else if (permissionStatus.state === "denied") {
        alert(
          "Microphone access is denied. Please grant permission in your browser settings."
        );
      }
    } catch (error) {
      console.error(
        "Error checking or requesting microphone permission:",
        error
      );
      return false;
    }
  };

  const startRecording = () => {
    const req = checkAndRequestPermission();
    if (req) {
      setAudioBlob(null);
      chunks.current = [];
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            setRecording(true);
            mediaRecorder.current = new MediaRecorder(stream);
            mediaRecorder.current.start();
          })
          .catch((error) => {
            console.error("Error accessing microphone:", error);
          });
      } else {
        console.error("getUserMedia not supported in this browser");
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
      mediaRecorder.current.stream.getTracks().forEach((track) => track.stop());
      setRecording(false);
    }
  };

  const resetAllSearchStates = () => {
    setSearchQuery("");
    setLoading(false);
    setFiles([]);
    deleteSavedRecording();
  };

  const handleSetSearchError = (message) => {
    setSearchError({ show: true, message });
    setLoading(false);
    updatePromptRunning({ promptState: false });
  };

  // function to set remaining free searches in DB
  const setRemainingSearchesOfUser = () => {
    axios
      .put(
        getURLs("set-searches"),
        {},
        { headers: { "auth-token": userInfo?.authToken } }
      )
      .then((res) => {
        updateSearchesLeftForUser(res?.data?.freeSearchesLeft);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // utility function to read image files
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  // handle retry for fetching bot and chat id in case of query error;
  const handleRetry = (visionRetry = false, retryFunc, ...params) => {
    return new Promise((resolve, reject) => {
      getBotIds(props.tutor).then((botIdRes) => {
        if (botIdRes?.length > 0) {
          const botIdInfo = {
            [`${props?.tutor}BotId`]: botIdRes,
          };
          setBotIds(botIdInfo, userInfo?.authToken);
          updateUserBotIdInfo({ ...botIdInfo });
          if (visionRetry) {
            getVisionChatIds(props?.tutor, botIdRes)
              .then((visionIdRes) => {
                if (visionIdRes) {
                  const chatIdInfo = {
                    [`${props?.tutor}VisionChatId`]: visionIdRes,
                  };
                  setChatIds(chatIdInfo, userInfo?.authToken);
                  updateUserChatIdInfo({ ...chatIdInfo });
                  retryFunc(...params);
                  resolve();
                }
              })
              .catch((err) => {
                handleSetSearchError(
                  err?.response?.data?.message ||
                    "Failed to fetch response from our servers, please try after sometime"
                );
              });
          } else {
            getTextChatIds(props?.tutor, botIdRes)
              .then((chatIdRes) => {
                if (chatIdRes) {
                  const chatIdInfo = {
                    [`${props?.tutor}ChatId`]: chatIdRes,
                  };
                  setChatIds(chatIdInfo, userInfo?.authToken);
                  updateUserChatIdInfo({ ...chatIdInfo });
                  retryFunc(...params);
                  resolve();
                }
              })
              .catch((err) => {
                handleSetSearchError(
                  err?.response?.data?.message ||
                    "Failed to fetch response from our servers, please try after sometime"
                );
              });
          }
        } else {
          handleSetSearchError(
            "Failed to fetch response from our servers, please try after sometime"
          );
        }
      });
    });
  };

  const handleSearchWithImage = async (attempts = 1) => {
    // cancel any ongoing api call if any
    abortAPICall();
    // creating formData object
    const apiData = new FormData();
    const processedImages = [];
    // Append each image to the formData with the same key ("file")
    files.forEach((image, index) => {
      apiData.append("files", image, `math_image${index + 1}.jpg`);
    });

    // for each image file get its base64 uri to store with response
    for (const file of files) {
      try {
        readFile(file)
          .then((imageURI) => {
            processedImages.push(imageURI);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }

    // Append the text field to the formData
    apiData.append("query", searchQuery);
    // creating an abort controller
    apiAbortController.current = new AbortController();
    const signal = apiAbortController.current.signal;

    axios
      .post(
        getBotURLs("new_vision", {
          botName: props.tutor,
          botId: userBotInfo?.botIds?.[`${props?.tutor}BotId`],
          visionId: userBotInfo?.botChatIds?.[`${props?.tutor}VisionChatId`],
        }),
        apiData,
        {
          signal,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        if (res.data?.response) {
          const newChat = [
            { value: searchQuery, type: "text" },
            { value: res.data?.response, text: "text" },
            { value: processedImages, type: "img" },
          ];
          handleIncludeImagesAndVideos(res);
          handleSearchResponse(newChat);
        } else {
          console.error(`VISION_API_ERROR: response returned as null`);
          resetAllSearchStates();
          handleSetSearchError(
            "Unable to process your request, please try later"
          );
        }
      })
      .catch((err) => {
        if (err?.name === "CanceledError") {
          handleSetSearchError("Request Cancelled");
          return;
        }
        if (attempts > 0) {
          attempts--;
          handleRetry(true, handleSearchWithImage, attempts);
        } else {
          handleSetSearchError(
            err?.response?.data?.message ||
              "Failed to fetch response from our servers, please try after sometime"
          );
          updatePromptRunning({ promptState: false });
        }
      });
  };

  // handle audio search
  const handleSearchWithVoice = (attempts = 1) => {
    // cancel any request if there
    abortAPICall();
    // creating formData object
    const formData = new FormData();
    // appending audioFile to formData
    formData.append("file", audioBlob, "recording.wav");
    // creating an abort controller
    apiAbortController.current = new AbortController();
    const signal = apiAbortController.current.signal;
    axios
      .post(
        getBotURLs("new_voice", {
          botName: props.tutor,
          botId: userBotInfo?.botIds?.[`${props?.tutor}BotId`],
          chatId: userBotInfo?.botChatIds?.[`${props?.tutor}ChatId`],
        }),
        formData,
        {
          signal,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      )
      .then(async (res) => {
        try {
          // Extract the blob from the response data
          const blob = new Blob([res.data], { type: "audio/mpeg" });
          const questionAudioToSave = await readFile(audioBlob);
          const answerAudioToSave = await readFile(blob);

          const newChat = [
            { value: questionAudioToSave, type: "audio" },
            { value: answerAudioToSave, type: "audio" },
          ];
          handleIncludeImagesAndVideos(res);
          handleSearchResponse(newChat);
        } catch (error) {
          console.log(error);
          resetAllSearchStates();
          handleSetSearchError(
            "Unable to process the request, please try after sometime"
          );
        }
      })
      .catch((err) => {
        if (err?.name === "CanceledError") {
          handleSetSearchError("Request Cancelled");
          return;
        }

        if (attempts > 0) {
          attempts--;
          handleRetry(false, handleSearchWithVoice, attempts);
        } else {
          handleSetSearchError(
            err?.response?.data?.message ||
              "Failed to fetch response from our servers, please try after sometime"
          );
          updatePromptRunning({ promptState: false });
        }
      });
  };

  // handle search with text
  const handleSearchWithText = (attempts = 1) => {
    // cancel any ongoing api call if any
    abortAPICall();
    // creating url encoded query:
    const urlEncodedQuery = encodeURIComponent(searchQuery);
    // creating an abort controller
    apiAbortController.current = new AbortController();
    const signal = apiAbortController.current.signal;
    axios
      .get(
        getBotURLs("new_text", {
          botName: props.tutor,
          botId: userBotInfo?.botIds?.[`${props?.tutor}BotId`],
          chatId: userBotInfo?.botChatIds?.[`${props?.tutor}ChatId`],
          query: urlEncodedQuery,
        }),
        { signal }
      )
      .then((res) => {
        if (res.data?.response) {
          const newChat = [
            {
              value: searchQuery,
              type: "text",
            },
            { value: res.data.response, type: "text" },
          ];
          if (initialSearch?.length > 0) {
            setInitialSearch("");
            props.searchValue = "";
          }
          handleIncludeImagesAndVideos(res);
          handleSearchResponse(newChat);
        } else {
          console.error(`TEXT_API_ERROR: response returned as null`);
          resetAllSearchStates();
          handleSetSearchError(
            "Unable to process your request, please try after sometime"
          );
        }
      })
      .catch((err) => {
        if (err?.name === "CanceledError") {
          handleSetSearchError("Request Cancelled");
          return;
        }
        if (attempts > 0) {
          attempts--;
          handleRetry(false, handleSearchWithText, attempts);
        } else {
          handleSetSearchError(
            err?.response?.data?.message ||
              "Failed to fetch response from our servers, please try after sometime"
          );
        }
      });
  };

  // handles response for each search
  const handleSearchResponse = (newChat) => {
    if (apiAbortController.current) {
      abortAPICall();
    }
    setCurrentChats([...currentChats, newChat]);
    setSearchError({ show: false, message: "" });
    setLoading(false);
    resetAllSearchStates();
    updatePromptRunning({ promptState: false });
    if (isSearchMade)
      // setting remaining searches
      setRemainingSearchesOfUser();
  };

  const handleIncludeImagesAndVideos = (axiosRes) => {
    const { search_results } = axiosRes.data || {};

    if (search_results?.images?.length) {
      setPromotionImages([...search_results.images]);
    }

    if (search_results?.videos?.length) {
      setPromotionVideos([...search_results.videos]);
    }
  };

  const handleStopPrompt = (e) => {
    e.preventDefault();
    if (apiAbortController.current) {
      abortAPICall();
    } else {
      const updatedCurrentChats = [...currentChats];
      if (updatedCurrentChats.length > 0) {
        const lastChat = updatedCurrentChats[updatedCurrentChats.length - 1];
        const updatedLastChat = [
          { value: lastChat[0].value, type: "text" },
          { value: "Generation Stopped", type: "text" },
        ];
        updatedCurrentChats[updatedCurrentChats.length - 1] = updatedLastChat;
      }
      setCurrentChats(updatedCurrentChats);
      setSearchError({ show: false, message: "" });
      resetAllSearchStates();
    }
    updateStopPrompt({ promptState: true });
    updatePromptRunning({ promptState: false });
    setTimeout(() => {
      updateStopPrompt({ promptState: null });
    }, 1000);
  };

  const abortAPICall = () => {
    if (apiAbortController.current) {
      apiAbortController.current.abort();
      apiAbortController.current = null;
    }
  };

  const isValidSearch = () => {
    if (searchQuery.trim(" ").length === 0) {
      setInputTextError(true);
      return false;
    }
    setInputTextError(false);
    return true;
  };

  // handle search
  const search = (e) => {
    e.preventDefault();

    if (
      searchesLeftForUser === 0 &&
      (!userInfo?.subscriptionDetails ||
        userInfo?.subscriptionDetails?.subscriptionId?.planName?.toLowerCase() ===
          "basic")
    ) {
      setSearchError({
        show: true,
        message:
          "Your free trial has been exhausted, Please subscribe to continue searching...",
      });
      setLoading(false);
      setOpenTrialOverModal(true);
      return;
    }

    if (Object.keys(userInfo).length > 0 && !failedToInitialise) {
      if (files?.length > 0 && isValidSearch()) {
        setLoading(true);
        setIsSearchMade(true);
        updatePromptRunning({ promptState: true });
        handleSearchWithImage();
      } else if (audioBlob) {
        setLoading(true);
        setIsSearchMade(true);
        updatePromptRunning({ promptState: true });
        handleSearchWithVoice();
      } else {
        if (isValidSearch()) {
          setLoading(true);
          setIsSearchMade(true);
          updatePromptRunning({ promptState: true });
          handleSearchWithText();
        }
      }
    } else {
      navigate("/login");
    }
  };

  // search input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // handle files
  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const newFile = Array.from(e.target.files);
      setFiles((prev) => [...prev, ...newFile]);
    }
  };

  const removeSelectedFile = (index) => {
    const filteredFiles = files.filter((file, i) => i !== index);
    setFiles(filteredFiles);
  };

  const checkAndSetChatIds = () => {
    if (userBotInfo?.botIds?.[`${props?.tutor}BotId`] && userInfo?.authToken) {
      if (
        !userBotInfo?.botChatIds?.[`${props?.tutor}ChatId`] &&
        !chatIdApiRunning
      ) {
        setChatIdApiRunning(true);
        getTextChatIds(
          props?.tutor,
          userBotInfo?.botIds[`${props?.tutor}BotId`]
        )
          .then((res) => {
            if (res) {
              const chatIdInfo = {
                [`${props?.tutor}ChatId`]: res,
              };
              setChatIds(chatIdInfo, userInfo?.authToken);
              updateUserChatIdInfo({ ...chatIdInfo });
              setInitialisingBot(false);
              setChatIdApiRunning(false);
            } else {
              setInitialisingBot(false);
              setChatIdApiRunning(false);
              setFailedToInitialise(true);
              setShowFailedModal(true);
            }
          })
          .catch((err) => {
            // disable search as chat id not available
            setInitialisingBot(false);
            setChatIdApiRunning(false);
            setFailedToInitialise(true);
            setShowFailedModal(true);
            console.log(err);
          });
      }

      if (
        !userBotInfo?.botChatIds?.[`${props?.tutor}VisionChatId`] &&
        !visionChatIdApiRunning
      ) {
        setVisionChatIdApiRunning(true);
        getVisionChatIds(
          props?.tutor,
          userBotInfo?.botIds[`${props?.tutor}BotId`]
        )
          .then((res) => {
            if (res) {
              const chatIdInfo = {
                [`${props?.tutor}VisionChatId`]: res,
              };
              setChatIds(chatIdInfo, userInfo?.authToken);
              updateUserChatIdInfo({ ...chatIdInfo });
              setInitialisingBot(false);
              setVisionChatIdApiRunning(false);
            } else {
              setInitialisingBot(false);
              setVisionChatIdApiRunning(false);
              setFailedToInitialise(true);
              setShowFailedModal(true);
            }
          })
          .catch((err) => {
            // disable search as chat id not available
            setInitialisingBot(false);
            setVisionChatIdApiRunning(false);
            setFailedToInitialise(true);
            setShowFailedModal(true);
            console.log(err);
          });
      }
    }
  };

  // check for the tutor bot id exists or not
  useEffect(() => {
    if (!userBotInfo?.botIds?.[`${props?.tutor}BotId`] && userInfo?.authToken) {
      setInitialisingBot(true);
      getBotIds(props.tutor)
        .then((res) => {
          if (res?.length > 0) {
            const botIdInfo = {
              [`${props?.tutor}BotId`]: res,
            };
            setBotIds(botIdInfo, userInfo?.authToken);
            updateUserBotIdInfo({ ...botIdInfo });
          } else {
            setInitialisingBot(false);
            setFailedToInitialise(true);
            setShowFailedModal(true);
          }
        })
        .catch((err) => {
          // disable search for user
          setInitialisingBot(false);
          setFailedToInitialise(true);
          setShowFailedModal(true);
          console.log(err);
        });
    }
  }, [userBotInfo, props?.tutor, userInfo]);

  // checks for chatid for the bot present
  useEffect(() => {
    if (userBotInfo?.botIds?.[`${props?.tutor}BotId`] && userInfo?.authToken) {
      if (
        !userBotInfo?.botChatIds?.[`${props?.tutor}ChatId`] ||
        !userBotInfo?.botChatIds?.[`${props?.tutor}VisionChatId`]
      ) {
        setInitialisingBot(true);
        checkAndSetChatIds();
      } else {
        setInitialisingBot(false);
      }
    }
  }, [userBotInfo, props?.tutor, userInfo]);

  // set text input error to false when typing
  useEffect(() => {
    if (inputTextError) {
      setInputTextError(false);
    }
  }, [searchQuery, inputTextError]);

  // remove the search error after 2 seconds
  useEffect(() => {
    if (searchError.show) {
      setTimeout(() => {
        setSearchError({ show: false, message: "" });
      }, 2000);
    }
  }, [searchError]);

  // search whenever we have a search value
  useEffect(() => {
    if (
      initialSearch &&
      userBotInfo?.botIds?.[`${props?.tutor}BotId`] &&
      userBotInfo?.botChatIds?.[`${props?.tutor}ChatId`]
    ) {
      if (apiAbortController.current) {
        abortAPICall();
      }
      setCurrentChats([]);
      setTimeout(() => {
        setLoading(true);
        handleSearchWithText(0);
      }, 30);
    }
  }, [initialSearch, userBotInfo, props?.tutor]);

  useEffect(() => {
    if (props?.searchValue) {
      setInitialSearch(props?.searchValue);
      setSearchQuery(props?.searchValue);
    }
  }, [props?.searchValue]);

  console.log(loading, props?.searchValue, initialSearch);

  return (
    <>
      <div className={`py-2 px-2 relative overflow-y-hidden w-full`}>
        {/* display bar */}
        {Object.keys(userInfo).length > 0 && (
          // user is logged in
          <MathJaxContext version={3} config={mathjaxConfig}>
            <MathJax>
              <div
                className="flex flex-col h-[73vh] lg:h-[84vh] w-full break-words"
                ref={scrollableRef}
              >
                <div className="relative flex-1 mb-2 flex flex-col xl:flex-row items-start space-x-0 space-y-2 xl:space-y-0 xl:space-x-2 pb-2 overflow-y-auto">
                  <div className="xl:basis-5/6 w-full relative overflow-y-auto h-full flex-1 rounded-3xl border border-gray-300 dark:border-purple15 px-3 py-3">
                    {currentChats?.length > 0 && (
                      <ShowPreviousChats history={currentChats} />
                    )}

                    {!loading && !initialisingBot && searchError?.show && (
                      <span className="font-urbanist font-semibold text-red-600 text-base">
                        {searchError?.message}
                      </span>
                    )}
                  </div>
                  {(loading || initialisingBot) && (
                    <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-10 bg-black/20 rounded-3xl !mt-0">
                      <Loader />
                    </div>
                  )}
                  {/* promotion container */}
                  <div className="xl:basis-1/6 xl:h-full w-full">
                    <PromotionCardsContainer
                      promotionImages={promotionImages}
                      promotionVideos={promotionVideos}
                    />
                  </div>
                </div>

                {/* files */}
                {files?.length > 0 && (
                  <div className="flex items-center space-x-4 flex-wrap py-2">
                    {files?.map((file, index) => {
                      const img = URL.createObjectURL(file);
                      return (
                        <div className="relative" key={index}>
                          <img
                            key={index}
                            src={img}
                            alt="upload_thumbnail"
                            className="h-24 w-24 rounded-md object-cover"
                          />
                          <div
                            className="absolute -top-2 -right-2 cursor-pointer h-5 w-5 flex items-center justify-center rounded-full bg-gray-300 opacity-60"
                            onClick={() => removeSelectedFile(index)}
                          >
                            <XMarkIcon className="h-3 w-3 text-black" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </MathJax>
          </MathJaxContext>
        )}

        {/* search bar */}
        <div
          className={`w-full relative rounded-[90px] border dark:border-none ${
            inputTextError ? "border-red-500" : "border-blue-300"
          } z-10 space-x-2`}
        >
          <form
            onSubmit={search}
            className="flex rounded-[90px] items-center flex-grow dark:bg-purple2 bg-white py-1 md:py-2 px-2 md:px-4 lg:py-3 lg:px-4 space-x-2"
          >
            <div>
              <input
                type="file"
                id="input-file-upload"
                hidden
                multiple
                onChange={handleChange}
              />
              <label id="label-file-upload" htmlFor="input-file-upload">
                <CameraIcon className="h-5 w-5 dark:text-gray39 text-gray-500 mr-1 cursor-pointer" />
              </label>
            </div>

            {/* vertical divder */}
            <div className="h-6 border-l border-l-gray-300" />
            {/* input */}
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleInputChange(e)}
              placeholder={appData?.homePageData?.searchBarPlaceholderText}
              className="flex-grow border-none outline-none text-sm placeholder:dark:text-gray39 placeholder:text-xs font-inter font-normal placeholder:text-gray30 dark:text-gray39 text-gray30 bg-transparent"
              disabled={loading}
            />

            {/* icons */}
            <div className="flex items-center space-x-2 h-full">
              <MicrophoneIcon
                onClick={startRecording}
                disabled={recording}
                className="w-5 h-5 lg:w-6 lg:h-6 text-gray30 cursor-pointer"
              />
              <div>
                {!promptRunning ? (
                  <button
                    type="submit"
                    className={`flex items-center justify-center h-12 w-12 md:h-max md:w-max md:px-4 md:py-2 text-sm font-urbanist font-medium cursor-pointer bg-blue31 disabled:bg-blue-400/60 rounded-full text-white`}
                    onClick={search}
                    disabled={failedToInitialise || loading}
                  >
                    <span className="hidden md:block">Search</span>
                    <PaperAirplaneIcon className="h-5 w-5 text-white md:hidden" />
                  </button>
                ) : (
                  <button
                    className={`flex items-center justify-center h-12 w-12 md:h-max md:w-max md:px-4 md:py-2 bg-black3 rounded-full`}
                    onClick={handleStopPrompt}
                    type="button"
                  >
                    <StopCircleIcon className="h-5 w-5 text-white" />
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      {audioBlob && (
        <div>
          <div className="flex items-center space-x-2 justify-between bg-white rounded-md shadow-md w-full mt-4 px-4 py-3">
            <div className="flex items-center space-x-2">
              <audio ref={audioRef} controls>
                <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
              <span className="text-gray-600 font-inter font-nomal text-sm">
                Recording.wav
              </span>
            </div>
            <XMarkIcon
              className="h-5 w-5 text-gray5 cursor-pointer"
              onClick={deleteSavedRecording}
            />
          </div>
          <span className="font-normal font-inter text-gray20 text-sm">
            Press the search icon to upload the audio and search
          </span>
        </div>
      )}

      {openTrialOverModal && (
        <TrialOverModel handleClosePopup={() => setOpenTrialOverModal(false)} />
      )}

      <CustomModal show={recording}>
        {recording && (
          <div className="bg-white px-5 py-4 space-y-5">
            <div className="space-y-1">
              <p className="font-inter font-semibold md:text-lg text-gray-800">
                Recording...
              </p>
              <p className="font-inter font-medium text-sm md:text-base text-gray20">
                Press stop button once you want to stop the recording
              </p>
            </div>
            <button
              className="bg-red-500 text-white rounded-md px-4 py-2 flex self-end "
              onClick={stopRecording}
              disabled={!recording}
            >
              Stop Recording
            </button>
          </div>
        )}
      </CustomModal>

      <CustomModal
        show={showFailedModal}
        centered
        onHide={() => setShowFailedModal(false)}
      >
        {failedToInitialise && (
          <div className="bg-white px-5 py-4">
            <div className="absolute top-1 right-2">
              <XCircleIcon
                className="h-5 w-5 text-gray-500 cursor-pointer"
                onClick={() => {
                  setShowFailedModal(false);
                }}
              />
            </div>
            <p className="font-render_ai_text font-normal text-sm md:text-base text-gray20">
              Failed to initalise course, Please try refreshing the page...
            </p>
          </div>
        )}
      </CustomModal>
    </>
  );
};

export default CoursesHero;
