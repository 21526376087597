import React, { useContext, useEffect, useState } from "react";
import {
  PencilSquareIcon,
  PhotoIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/user";
import { getURLs } from "../../../urlConfig";
import AdminFilterTabsWithSearch from "../../../components/AdminPageComponents/AdminFilterTabsWithSearch";
import CustomPagination from "../../../components/CustomPagination";

const tabs = ["All", "Approved", "Pending", "Rejected", "Draft"];

const AdminShowBlogRequests = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("all");
  const [pagedResponse, setPagedResponse] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // disable button state
  const [disbaleActionButtons, setDisableActionButtons] = useState(false);
  // make fresh request state
  const [fetchFreshRecords, setFetchFreshRecords] = useState(true);
  // handler func for search
  const handleSearch = (value) => {
    // setting the current search term
    setSearchTerm(value);
    // resetting the states
    setPage(1);
    // reset the final page
    setFinalPage(1);
    // set the paged response state to initial
    setPagedResponse({});
    // setting the fetch fresh record state to true
    setFetchFreshRecords(true);
  };

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    setFetchFreshRecords(true);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  const handleEditButtonClick = (blogInfo) => {
    navigate(`/admin/update-blog/${blogInfo?.urlSlug}`, {
      state: {
        blogData: blogInfo,
      },
    });
  };

  const handleChangeStatus = (id, status) => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin")
    ) {
      if (disbaleActionButtons) return;
      setDisableActionButtons(true);
      axios
        .post(
          getURLs("change-status"),
          {
            status,
            changeStatusFor: "blog",
            id,
          },
          {
            withCredentials: true,
            headers: {
              "auth-token": userInfo?.authToken,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const updatedPagedResponse = { ...pagedResponse };

            updatedPagedResponse[page] = updatedPagedResponse[page]?.map(
              (blogItem) => {
                if (blogItem._id === id) {
                  return {
                    ...blogItem,
                    status: status,
                  };
                } else {
                  return blogItem;
                }
              }
            );
            setPagedResponse(updatedPagedResponse);
            setDisableActionButtons(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setDisableActionButtons(false);
        });
    }
  };

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      (!pagedResponse[page] || fetchFreshRecords)
    ) {
      setLoading(true);
      axios
        .get(getURLs("fetch-blogs"), {
          params: {
            page,
            search: searchTerm,
            categories: ["all"],
            status: selectedTab,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          // setBlogs([...blogs, ...res.data?.blogs]);
          setPagedResponse((prevPageResponses) => ({
            ...prevPageResponses,
            [page]: res.data?.blogs,
          }));
          setFirstResponseCame(true);
          setFetchFreshRecords(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchFreshRecords(false);
          setLoading(false);
        });
    }
  }, [
    page,
    searchTerm,
    selectedTab,
    userInfo,
    pagedResponse,
    fetchFreshRecords,
  ]);

  return (
    <div className="flex flex-col bg-bgColor3 dark:bg-darkBgColor1 px-4 py-5">
      <h1 className="font-inter font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        All Available Blogs
      </h1>
      {/* filter section */}
      <AdminFilterTabsWithSearch
        handleSearch={handleSearch}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
        tabsData={tabs}
      />

      {/* table */}
      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 scrollbar-thin scrollbar-track-slate-300">
        {/* header */}
        <div className="grid grid-cols-8 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple26 min-w-[800px]">
          <div className="tableHeaderText col-span-2 text-start">Blog Info</div>
          <div className="tableHeaderText col-span-2">Author Info</div>
          <div className="tableHeaderText">Read Time</div>
          <div className="tableHeaderText">Status</div>
          <div className="tableHeaderText border-none text-center col-span-2">
            Action
          </div>
        </div>

        <div className="min-w-[800px]">
          {!loading ? (
            pagedResponse[page]?.length > 0 ? (
              pagedResponse[page]?.map((blogInfo) => (
                <div className="" key={blogInfo._id}>
                  {/* body */}
                  <div className="grid grid-cols-8 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple26">
                    {/* info */}
                    <div className="col-span-2 flex flex-col md:flex-row items-start md:items-center space-y-2 space-x-0 md:space-y-0 md:space-x-4">
                      {blogInfo?.image ? (
                        <div className="w-24">
                          <img
                            src={blogInfo.image}
                            alt={blogInfo?.title}
                            className="h-20 w-20 object-cover rounded-md"
                          />
                        </div>
                      ) : (
                        <PhotoIcon className="rounded-md h-20 w-20 text-gray20 dark:text-purple25" />
                      )}
                      <h2 className="font-urbanist font-medium text-black dark:text-white w-full truncate">
                        {blogInfo?.title}
                      </h2>
                    </div>

                    {/* name */}
                    <div className="col-span-2 flex flex-col space-y-0.5 items-center font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {blogInfo?.authorImage ? (
                        <img
                          src={blogInfo?.authorImage}
                          alt={blogInfo?.author}
                          className="h-8 w-8 rounded-full object-cover"
                        />
                      ) : (
                        <UserCircleIcon className="text-gray-300 dark:text-purple25 h-8 w-8" />
                      )}
                      <p className="">Name: {blogInfo?.author}</p>
                      <p className="">
                        Designation: {blogInfo?.authorDesignation || "-"}
                      </p>
                    </div>

                    <p className="font-urbanist text-sm text-black dark:text-white text-center font-medium">
                      {blogInfo?.readTime || "-"}
                    </p>

                    {/* status */}
                    <div className="flex items-center justify-center gap-1 h-max">
                      <div
                        className={`${
                          blogInfo?.status === "pending"
                            ? "bg-orange-500"
                            : blogInfo?.status === "approved"
                            ? "bg-green-500"
                            : blogInfo?.status === "rejected"
                            ? "bg-red-500"
                            : "bg-yellow-500"
                        } flex h-2 w-2 rounded-full`}
                      />
                      <p className="font-urbanist text-sm font-semibold dark:text-white ">
                        {blogInfo?.status?.toUpperCase() || "DRAFT"}
                      </p>
                    </div>

                    {/* action */}
                    <div className="flex items-center gap-2 justify-center flex-wrap col-span-2 ">
                      <button
                        className="flex items-center justify-center px-5 py-1 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
                        style={{
                          background: !disbaleActionButtons
                            ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                            : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                        }}
                        disabled={disbaleActionButtons}
                        onClick={() => handleEditButtonClick(blogInfo)}
                      >
                        <PencilSquareIcon className="text-white h-4 w-4 mr-2" />
                        Edit
                      </button>

                      {blogInfo?.status && blogInfo?.status !== "draft" && (
                        <div className="flex items-center justify-center space-x-2 font-urbanist font-medium text-sm">
                          {blogInfo.status !== "approved" && (
                            <button
                              className="bg-background8 disabled:bg-background6 font-urbanist font-medium text-white rounded-xl px-2 py-1 text-sm"
                              onClick={() =>
                                handleChangeStatus(blogInfo._id, "approved")
                              }
                              disabled={disbaleActionButtons}
                            >
                              Approve
                            </button>
                          )}
                          {blogInfo.status !== "pending" && (
                            <button
                              className="px-6 py-1 text-black1 disabled:text-gray-300 dark:disabled:text-gray-500 dark:text-white font-urbanist font-medium rounded-xl border border-gray-400 disabled:bg-gray-50 dark:bg-purple24 dark:disabled:bg-purple26/30 dark:border-purple26 dark:border-purple-26 text-sm"
                              onClick={() =>
                                handleChangeStatus(blogInfo._id, "pending")
                              }
                              disabled={disbaleActionButtons}
                            >
                              Pending
                            </button>
                          )}
                          {blogInfo.status !== "rejected" && (
                            <button
                              className="rounded-xl border dark:border-none text-white px-2 py-1 bg-red-500 disabled:bg-red-200"
                              onClick={() =>
                                handleChangeStatus(blogInfo._id, "rejected")
                              }
                              disabled={disbaleActionButtons}
                            >
                              {"Reject"}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                No Blogs available
              </p>
            )
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              Loading
            </p>
          )}
        </div>
      </div>

      {firstResponseCame && finalPage !== 1 && (
        <CustomPagination
          handlePrevClick={handlePrevPage}
          currentPage={page}
          handleNextClick={handleLoadMore}
          totalPages={finalPage}
        />
      )}
    </div>
  );
};

export default AdminShowBlogRequests;
