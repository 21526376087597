import React, { useContext } from "react";
import { AppDataContext } from "../../../context/appData";
import { DarkModeContext } from "../../../context/darkModeContext";
import { Link } from "react-router-dom";
import UserSidebarTab from "./UserSidebarTab/UserSidebarTab";
import DarkModeToggle from "../../DarkModeToggle";
import SidebarPromotionBox from "./SidebardPromotionBox/SidebarPromotionBox";

const UserDashboardSidebar = ({ TabsData }) => {
  const {
    state: { appData },
  } = useContext(AppDataContext);
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  return (
    <div className="flex flex-col dark:bg-darkBgColor1 bg-white px-4 py-4 lg:min-h-screen h-full w-full">
      <div className="flex-1 flex flex-col">
        <Link to={"/"} className="mb-3 hidden lg:inline-flex">
          <div className="flex items-center space-x-2 cursor-pointer">
            {Object.keys(appData).length ? (
              <img
                loading="lazy"
                src={
                  darkMode
                    ? appData?.homePageData?.darkModeLogoUrl
                    : appData?.homePageData?.logoUrl
                }
                alt="logo"
                className="h-16 object-contain mb-2"
              />
            ) : (
              <span>Loading...</span>
            )}
          </div>
        </Link>

        <div className="flex flex-col space-y-1">
          {TabsData?.map((tab) => (
            <UserSidebarTab
              key={tab.id}
              title={tab.text}
              Icon={tab.icon}
              isImg={tab?.isImg}
              IconDark={tab?.darkModeIcon}
              route={tab?.route}
            />
          ))}
        </div>

        <div className="mt-2 pb-2 flex items-center justify-center w-max mx-auto">
          <DarkModeToggle useInDashboard />
        </div>
      </div>

      {/* ad box */}
      <SidebarPromotionBox />

      <div className="">
        <div className="bg-purple15/10 dark:border dark:border-purple15 px-4 py-4 rounded-lg">
          <p className="font-urbanist font-semibold text-black1 dark:text-white text-center mb-2">
            Get Premium
          </p>
          <p className="font-inter font-normal text-xs text-gray10 text-center mb-4">
            Buy premium and get access to premium courses
          </p>
          <Link
            to={"/dashboard/profile"}
            state={{
              selectedOption: {
                id: 2,
                text: "Subscriptions",
                selectedValue: "subscriptions",
              },
            }}
          >
            <button className="py-3 px-4 w-full bg-purple6 text-white text-center font-urbanist font-normal rounded-lg">
              Subscribe
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardSidebar;
