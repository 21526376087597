import React, { useContext } from "react";
import { UserContext } from "../../../context/user/index.js";
import AccountOverview from "../../../components/InstructorDashboardComponents/AccountOverview/AccountOverview.js";
import { InstructorDataContext } from "../../../context/instructorData/index.js";

const NewInstructorDashboard = () => {
  // user info context
  const {
    state: { userInfo },
  } = useContext(UserContext);

  // getting the instructor context
  const {
    state: { instructorCourses },
  } = useContext(InstructorDataContext);

  return (
    <>
      <AccountOverview
        instructorCourses={instructorCourses}
        userInfo={userInfo}
      />
    </>
  );
};

export default NewInstructorDashboard;
