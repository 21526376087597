import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import { Outlet, useNavigate } from "react-router-dom";
import Loader from "../Loader";

const AdminAuthWrapper = () => {
  const {
    state: { userInfo, userUpdated },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (userUpdated) {
      if (!userInfo || (userInfo && !userInfo?.role?.includes("admin"))) {
        navigate("/", { replace: true });
      }
      setLoading(false);
    }
  }, [userInfo, userUpdated, navigate]);
  return !loading ? (
    <Outlet />
  ) : (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <Loader />
    </div>
  );
};

export default AdminAuthWrapper;
