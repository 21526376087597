import React, { useContext, useState } from "react";
import axios from "axios";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../../context/user";
import { getURLs } from "../../../urlConfig";
import ImageUpload from "../../../components/ImageUpload";
import CustomModal from "../../../components/CustomModal/CustomModal";

const AdminAddCourseThroughCSV = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [error, setError] = useState({ show: false, message: "" });
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const removeSelection = () => {
    setFile(null);
  };

  const handleUploadCourse = () => {
    if (uploading) return;
    setUploading(true);
    // creating formData object
    const formData = new FormData();
    // appending audioFile to formData
    formData.append("upload_file", file);

    axios
      .post(getURLs("upload-course-through-csv"), formData, {
        headers: {
          "auth-token": userInfo?.authToken,
        },
      })
      .then((res) => {
        setUploading(false);
        setTaskId(res.data.task_id);
        setShowSuccessModal(true);
        setFile(null);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
        setError({ show: true, message: "Failed to upload course" });
      });
  };

  return (
    <div className="flex flex-col p-5">
      <h5 className="font-urbanist font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        Upload Course File
      </h5>

      <div className="mx-auto max-w-4xl w-full mt-10 space-y-5">
        <input
          type="file"
          id="input-file-upload-course"
          hidden
          accept=".csv"
          onChange={handleFileChange}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        <label
          htmlFor="input-file-upload-course"
          className="flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-y-0 md:space-x-2 w-full"
        >
          <div className="w-full">
            <ImageUpload acceptedTypes="Only CSV files" />
          </div>
        </label>

        {file && (
          <div className="bg-gray-300 rounded-md px-4 py-2 w-max flex items-center justify-between space-x-5">
            <p className="font-urbanist font-medium text-gray-600 dark:text-white">
              Uploaded File: {file?.name}
            </p>
            <XMarkIcon
              className="text-gray-600 dark:text-white h-5 w-5 cursor-pointer"
              onClick={removeSelection}
            />
          </div>
        )}

        {error.show && (
          <p className="text-red-500 my-5 font-urbanist font-medium block text-sm">
            {error.message}
          </p>
        )}

        <button
          className="px-10 py-2 w-max font-urbanist font-semibold text-white text-sm border-none rounded-xl"
          style={{
            background:
              file && !uploading
                ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
          }}
          disabled={!file || uploading}
          onClick={handleUploadCourse}
        >
          Add Course
        </button>
      </div>
      <CustomModal show={showSuccessModal} centered>
        {showSuccessModal && (
          <div className="relative flex flex-col space-y-1 py-4 px-6">
            <div className="absolute right-4">
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={() => setShowSuccessModal(false)}
              />
            </div>
            <h4 className="text-sm font-inter font-medium flex-1 text-start text-black pr-8">
              Course uploaded successfully, Please note the id:{" "}
              <span className="font-bold">{taskId}</span> to check the status of
              the uploaded course
            </h4>
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default AdminAddCourseThroughCSV;
