import React from "react";
import CreateCourseSidebarItem from "./CreateCourseSidebarItem/CreateCourseSidebarItem";
import {
  ChevronLeftIcon,
  ClipboardDocumentIcon,
  PlusIcon,
  RadioIcon,
} from "@heroicons/react/24/outline";
import CourseDetailsSidebar from "../../CourseDetailsSectionContent/CourseDetailsSidebar/CourseDetailsSidebar";

const CreateCourseSidebar = ({
  step,
  setStep,
  expanded,
  setExpanded,
  courseData,
  sectionContent,
  setSectionContent,
  handleConfirmPublish,
  publishButtonDisable,
}) => {
  const handleStepChange = (stepNumber) => {
    setStep(stepNumber);
  };

  return (
    <div className="md:border-r md:border-r-gray39 dark:border-r-purple26 h-full">
      <div className="px-6 py-3 md:border-b md:border-b-gray39 md:dark:border-b-purple26 font-urbanist font-semibold text-black dark:text-white text-sm md:block mb-4">
        {step === 5 ? (
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => handleStepChange(4)}
          >
            <ChevronLeftIcon className="text-purple6 h-5 w-5" />
            <div className="flex items-center space-x-2">
              <RadioIcon className="h-5 w-5 text-purple6" />
              <p className="text-purple6">Preview</p>
            </div>
          </div>
        ) : (
          <p>Table of contents</p>
        )}
      </div>

      {step !== 5 ? (
        <>
          <div className="flex flex-row flex-wrap md:flex-col gap-4 space-y-0 md:gap-0 md:space-x-0 md:space-y-4 px-4 py-2 md:border md:border-gray39 md:dark:border-purple26 rounded-lg mx-4 my-5">
            <CreateCourseSidebarItem
              Icon={ClipboardDocumentIcon}
              currentStep={3}
              name={"Add Information"}
              selectedStep={step}
              handleStepChange={handleStepChange}
            />
            <CreateCourseSidebarItem
              Icon={PlusIcon}
              currentStep={4}
              name={"Add Section"}
              selectedStep={step}
              handleStepChange={handleStepChange}
            />
            <CreateCourseSidebarItem
              Icon={ClipboardDocumentIcon}
              currentStep={5}
              name={"Preview"}
              selectedStep={step}
              handleStepChange={handleStepChange}
            />
          </div>

          <div className="px-4">
            <button
              onClick={handleConfirmPublish}
              disabled={publishButtonDisable}
              className="flex items-center md:w-full py-2 px-4 rounded-lg"
              style={{
                background:
                  "linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%)",
              }}
            >
              <p className="w-full font-urbanist text-sm text-white text-center font-bold">
                Publish
              </p>
            </button>
          </div>
        </>
      ) : (
        <CourseDetailsSidebar
          expanded={expanded}
          setExpanded={setExpanded}
          courseData={courseData}
          sectionContent={sectionContent}
          setSectionContent={setSectionContent}
        />
      )}
    </div>
  );
};

export default CreateCourseSidebar;
