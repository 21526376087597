import React from "react";
import CustomModal from "../../components/CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";

const ShowPromotionDetailsModal = ({
  showModal,
  promotionDetails,
  handleClose,
}) => {
  return (
    <CustomModal centered show={showModal}>
      {showModal && (
        <div className="relative flex flex-col max-w-xl w-full px-4 py-6">
          <div className="absolute right-0 top-1">
            <XCircleIcon
              className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
              onClick={handleClose}
            />
          </div>
          <div className="flex flex-col w-full h-80 px-2 border border-gray-200 rounded-lg mt-5 py-5">
            {promotionDetails?.type === "img" ? (
              <img
                src={promotionDetails.value}
                alt=""
                className="w-full h-72 object-contain"
              />
            ) : (
              <ReactPlayer
                url={promotionDetails.value}
                width="100%"
                height="100%"
                muted={true}
                controls
              />
            )}
          </div>
        </div>
      )}
    </CustomModal>
  );
};

export default ShowPromotionDetailsModal;
