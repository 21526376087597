import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserContext } from "../../../context/user";
import { getURLs } from "../../../urlConfig";
import CustomPagination from "../../../components/CustomPagination";
import FilterDropdownWithLabel from "../../../components/FilterDropdownWithLabel";
import {
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import useDebounce from "../../../hooks/useDebounce";
import "./AdminUsageMetrics.css";
import AdminDateInput from "../../../components/AdminPageComponents/AdminDateInput";
import { format } from "date-fns";

const AdminUsageMetrics = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const [pagedResponse, setPagedResponse] = useState({});
  // search input states
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  // dropdown state
  const [openFiltersDropdown, setOpenFiltersDropdown] = useState(null);
  // filters
  const [filters, setFilters] = useState({});
  // applied filters
  const [appliedFilters, setAppliedFilters] = useState({
    email: "All",
    name: "All",
    bot: "All",
    startDate: "",
    endDate: "",
  });
  // page no state
  const [page, setPage] = useState(1);
  // final page state
  const [finalPage, setFinalPage] = useState(1);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // make fresh request state
  const [fetchFreshRecords, setFetchFreshRecords] = useState(true);
  // text copy state
  const [copyText, setCopyText] = useState({
    copyFor: "",
    text: "copy",
    id: "",
  });
  const { debounce } = useDebounce();

  const handleSearch = useCallback(
    debounce((searchVal) => {
      // setting the current search term
      setSearchTerm(searchVal);
      // resetting the states
      setPage(1);
      // reset the final page
      setFinalPage(1);
      // set the paged response state to initial
      setPagedResponse({});
      // fetch fresh records
      setFetchFreshRecords(true);
    }, 500),
    []
  );

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  // Function to toggle dropdown visibility
  const openFilters = (filterType) => {
    setOpenFiltersDropdown(
      openFiltersDropdown === filterType ? null : filterType
    );
  };

  // apply selected filter
  const applyFilter = (filterType, value) => {
    setAppliedFilters((prev) => ({ ...prev, [filterType]: value }));
    setOpenFiltersDropdown(null);
  };

  //   handle apply click
  const handleApplyClick = () => {
    setLoading(true);
    if (loading) return;
    setPage(1);
    setFinalPage(1);
    setPagedResponse({});
    setFetchFreshRecords(true);
  };

  const handleCopyClick = (copyFor, text, id) => {
    navigator.clipboard.writeText(text);
    setCopyText({ copyFor, text: "copied", id });
    setTimeout(() => {
      setCopyText({ copyFor: "", text: "copy", id: "" });
    }, 1000);
  };

  const formatDateTime = (date) => {
    return format(date, "yyyy-MM-dd HH:mm:ss");
  };

  useEffect(() => {
    // fetch filters
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin")
    ) {
      axios
        .get(getURLs("admin-filters"), {
          params: {
            page: "usage-metrics",
          },
          withCredentials: true,
          headers: { "auth-token": userInfo?.authToken },
        })
        .then((res) => {
          setFilters(res.data?.filters);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      (!pagedResponse[page] || fetchFreshRecords)
    ) {
      setLoading(true);
      axios
        .get(getURLs("all-search-history"), {
          params: {
            page,
            search: searchTerm,
            startDate: appliedFilters?.startDate,
            endDate: appliedFilters?.endDate,
            email: appliedFilters?.email,
            username: appliedFilters?.name,
            bot: appliedFilters?.bot,
          },
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setFinalPage((prev) => prev + 1);
          } else {
            setFinalPage(page);
          }
          setPagedResponse((prevPageResponses) => ({
            ...prevPageResponses,
            [page]: res.data?.searchesHistory,
          }));
          setFirstResponseCame(true);
          setFetchFreshRecords(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchFreshRecords(false);
          setLoading(false);
        });
    }
  }, [userInfo, page, searchTerm, pagedResponse, fetchFreshRecords]);

  return (
    <div className="flex flex-col bg-bgColor3 dark:bg-darkBgColor1 px-4 py-5">
      <h1 className="font-urbanist font-semibold text-xl md:text-2xl block mb-4 text-black dark:text-white">
        User Chats Usage Report
      </h1>
      {/* apply filters */}
      <div className="flex flex-col items-end space-y-2">
        <div className="flex items-center flex-wrap gap-2">
          {/* Email Filter */}
          <FilterDropdownWithLabel
            label={"Email"}
            filterFor={"email"}
            filterValues={filters?.userEmails}
            handleFilterSelect={applyFilter}
            dropdownOpen={openFiltersDropdown === "email"}
            openDropdown={openFilters}
            appliedFilterName={appliedFilters?.email}
            alignment="left"
            alignmentValue="left-0"
          />

          {/* Name Filter */}
          <FilterDropdownWithLabel
            label={"Name"}
            filterFor={"name"}
            filterValues={filters?.userNames}
            handleFilterSelect={applyFilter}
            dropdownOpen={openFiltersDropdown === "name"}
            openDropdown={openFilters}
            appliedFilterName={appliedFilters?.name}
          />

          {/* Bot Filter */}
          <FilterDropdownWithLabel
            label={"Bot"}
            filterFor={"bot"}
            filterValues={filters?.bots}
            handleFilterSelect={applyFilter}
            dropdownOpen={openFiltersDropdown === "bot"}
            openDropdown={openFilters}
            appliedFilterName={appliedFilters?.bot}
          />
        </div>
        <div className="flex space-x-2">
          <AdminDateInput
            selectedDate={appliedFilters?.startDate}
            applyFilter={applyFilter}
            filterFor={"startDate"}
            label={"Start Date"}
          />
          <AdminDateInput
            selectedDate={appliedFilters?.endDate}
            applyFilter={applyFilter}
            filterFor={"endDate"}
            label={"End Date"}
          />
        </div>

        <button
          className="bg-black2 dark:bg-white disabled:bg-black/20 disabled:dark:bg-gray-200 px-4 py-1 text-white dark:text-black2 font-inter font-medium text-sm text-center rounded-lg"
          onClick={handleApplyClick}
          disabled={loading}
        >
          Apply
        </button>

        {/* Search Input */}
        <div className="w-full">
          <input
            className="w-full px-2 py-2 border border-gray-300 dark:border-purple15 rounded-md bg-white dark:bg-purple14 placeholder:text-gray20 text-gray20 outline-none text-sm"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              handleSearch(e.target.value);
            }}
            placeholder="Search for name, email and search query"
          />
        </div>
      </div>

      <div className="flex flex-col border dark:border-purple15 rounded-md overflow-x-auto bg-white dark:bg-purple14 scrollbar-thin scrollbar-track-slate-300 mt-4">
        {/* header */}
        <div className="grid grid-cols-7 gap-1 rounded-md px-4 py-4 border-b border-b-gray-200 dark:border-b-purple26 min-w-[850px]">
          <div className="tableHeaderText">Email</div>
          <div className="tableHeaderText">Name</div>
          <div className="tableHeaderText">Bot</div>
          <div className="tableHeaderText col-span-2 text-center">Query</div>
          <div className="tableHeaderText">Date</div>
          <div className="tableHeaderText">User ID</div>
        </div>

        <div className="min-w-[850px]">
          {!loading ? (
            pagedResponse[page]?.length > 0 ? (
              pagedResponse[page]?.map((searchHistory) => (
                <div className="" key={searchHistory._id}>
                  {/* body */}
                  <div className="grid grid-cols-7 gap-1 rounded-md px-4 py-2 border-b border-b-gray-200 dark:border-b-purple26">
                    {/* info */}
                    <div className="flex items-center justify-center space-x-4">
                      <h2 className="font-urbanist font-medium text-black dark:text-white w-full truncate">
                        {searchHistory?.userEmail}
                      </h2>
                    </div>

                    {/* name */}
                    <p className="flex items-center justify-center font-urbanist text-center text-sm text-black dark:text-white font-medium w-full truncate">
                      {searchHistory?.userName}
                    </p>

                    {/* bot */}
                    <p className="flex items-center justify-center font-urbanist text-sm text-black dark:text-white text-center font-medium">
                      {searchHistory?.botName?.toUpperCase()}
                    </p>

                    {/* query */}
                    <div className="col-span-2 flex items-center justify-center space-x-1">
                      <p className="line-clamp-2 font-urbanist text-sm text-black dark:text-white text-center font-medium">
                        {searchHistory?.searchTerm}
                      </p>
                      <div className="w-5">
                        {copyText.copyFor === "searchTerm" &&
                        copyText.text === "copied" &&
                        copyText.id === searchHistory._id ? (
                          <ClipboardDocumentCheckIcon className="h-4 w-4 text-black dark:text-white" />
                        ) : (
                          <ClipboardIcon
                            className="h-4 w-4 text-black dark:text-white cursor-pointer"
                            onClick={() =>
                              handleCopyClick(
                                "searchTerm",
                                searchHistory.searchTerm,
                                searchHistory._id
                              )
                            }
                          />
                        )}
                      </div>
                    </div>

                    {/* date */}
                    <p className="flex items-center justify-center font-urbanist text-sm text-black dark:text-white text-center font-medium">
                      {formatDateTime(searchHistory?.searchTime)}
                    </p>

                    {/* user id */}
                    <div className="flex items-center justify-center space-x-1">
                      <p className="font-urbanist text-sm text-black dark:text-white text-center font-medium truncate flex-1">
                        {searchHistory?.userid}
                      </p>
                      {copyText.copyFor === "userid" &&
                      copyText.text === "copied" &&
                      copyText.id === searchHistory._id ? (
                        <ClipboardDocumentCheckIcon className="h-4 w-4 text-black dark:text-white" />
                      ) : (
                        <ClipboardIcon
                          className="h-4 w-4 text-black dark:text-white cursor-pointer"
                          onClick={() =>
                            handleCopyClick(
                              "userid",
                              searchHistory.userid,
                              searchHistory._id
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
                No search history available
              </p>
            )
          ) : (
            <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
              Loading...
            </p>
          )}
        </div>
      </div>

      {firstResponseCame && finalPage !== 1 && (
        <CustomPagination
          handlePrevClick={handlePrevPage}
          currentPage={page}
          handleNextClick={handleLoadMore}
          totalPages={finalPage}
        />
      )}
    </div>
  );
};

export default AdminUsageMetrics;
