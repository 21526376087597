import React, { useContext } from "react";
import InstructorAllCourses from "../../../components/InstructorDashboardComponents/InstructorAllCourses/InstructorAllCourses";
import { InstructorDataContext } from "../../../context/instructorData";

const InstructorShowAllCourses = () => {
  // getting the instructor context
  const {
    state: { instructorCourses },
  } = useContext(InstructorDataContext);

  return <InstructorAllCourses instructorCourses={instructorCourses} />;
};

export default InstructorShowAllCourses;
