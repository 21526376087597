import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import InstructorSidebar from "../InstructorSidebar";
import InstructorDashboardHeader from "../InstructorDashboardHeader";
import Loader from "../Loader";

const InstructorLayout = () => {
  const {
    state: { userInfo, userUpdated },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userUpdated) {
      if (
        !Object.keys(userInfo || {}).length
        // || !userInfo?.role?.includes("instructor")
      ) {
        navigate("/", {
          replace: true,
        });
      }
      setLoading(false);
    }
  }, [userInfo, userUpdated, navigate]);

  // Check if the route is "/edit-course" or "/add-new-course"
  const isEditOrAddNewCourse =
    location.pathname.includes("/dashboard") ||
    location.pathname.includes("/edit-course") ||
    location.pathname.includes("/add-new-course");
  console.log(isEditOrAddNewCourse);

  return (
    <>
      {!loading && !isEditOrAddNewCourse && (
        <div className="px-8 bg-gray-100 pb-10">
          <InstructorDashboardHeader />

          <div className="flex items-start">
            <section className="hidden lg:block lg:basis-1/4">
              <InstructorSidebar />
            </section>
            <section className="w-full lg:basis-3/4 px-0 lg:px-4">
              <Outlet />
            </section>
          </div>
        </div>
      )}
      {!loading && isEditOrAddNewCourse && <Outlet />}
      {loading && (
        <div className="absolute top-0 bottom-0 right-0 left-0 z-50 bg-black/10 flex items-center justify-center">
          <Loader />
        </div>
      )}
    </>
  );
};

export default InstructorLayout;
