import { CalendarIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";

const AdminDateInput = ({ selectedDate, applyFilter, filterFor, label }) => {
  const inputRef = useRef(null);
  return (
    <div className="relative date-input-wrapper flex items-center space-x-1 border bg-gray9 dark:bg-inherit dark:border-purple15 dark:text-white font-inter font-normal rounded-lg text-sm px-2 py-1">
      <p className="text-xs">{label}:</p>
      <input
        ref={inputRef}
        type="date"
        className="border w-full flex-1 font-semibold relative rounded-md bg-inherit outline-none border-none"
        value={selectedDate}
        onChange={(e) => applyFilter(filterFor, e.target.value)}
      />
      <CalendarIcon
        className="h-4 w-4 absolute right-1 top-2 cursor-pointer"
        onClick={() => inputRef?.current?.showPicker()}
      />
    </div>
  );
};

export default AdminDateInput;
