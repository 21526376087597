import React from "react";

const FilterDropdownWithLabel = ({
  label,
  openDropdown,
  appliedFilterName,
  dropdownOpen,
  filterFor,
  filterValues,
  handleFilterSelect,
  alignment = "right",
  alignmentValue = "",
}) => {
  const defaultAlignment = "right-0";
  return (
    <div className="relative">
      <div
        className="flex items-center space-x-1 justify-between cursor-pointer bg-gray9 dark:bg-inherit border dark:border-purple15 px-3 py-2 rounded-md font-inter font-normal text-xs text-black dark:text-white max-w-xs overflow-hidden"
        onClick={() => openDropdown(filterFor)}
      >
        <p>{label}:</p>
        <p className="trucate font-bold flex-1 flex justify-center">
          {appliedFilterName || "Select"}
        </p>
      </div>
      {dropdownOpen && (
        <div
          className={`absolute ${
            alignment !== "right" ? alignmentValue : defaultAlignment
          } mt-1 w-60 max-h-80 overflow-y-auto bg-white dark:bg-purple14 border dark:border-purple15 shadow-md rounded-md z-10`}
        >
          {filterValues?.map((value, index) => (
            <div
              key={`${value}_${index}`}
              className="border-b dark:border-b-purple15 px-4 py-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-purple16"
            >
              <p
                className="break-words w-full line-clamp-2 font-inter font-normal text-xs text-gray16 dark:text-white"
                onClick={() => handleFilterSelect(filterFor, value)}
              >
                {value}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropdownWithLabel;
