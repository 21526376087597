import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/user";
import MethodSelection from "../components/UserDashboard/PaymentSection/ChooseTimeline/MethodSelection/MethodSelection";
import PaypalImage from "../images/paypal.svg";
import StripeLogo from "../images/stripe.svg";
import { addSuffixToNumber } from "../utils/utils";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import CourseStripeCheckout from "../components/StripeCheckout/CourseStripeCheckout";
import CoursePaypalCheckout from "../components/PaypalCheckout/CoursePaypalCheckout";

const CourseCheckout = () => {
  const {
    state: { userInfo, userUpdated },
  } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const courseData = location?.state?.courseData;
  const courseSlug = location?.state?.courseSlug;

  // selected payment method state
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("paypal");
  const handlePaymentMethodSelection = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  useEffect(() => {
    if (userUpdated) {
      if (!Object.keys(userInfo || {}).length || !courseData)
        navigate("/", { replace: true });
    }
  }, [courseData, navigate, userUpdated, userInfo]);

  return (
    <div className="flex flex-col-reverse md:grid md:grid-cols-2">
      {/* left */}
      <div className="flex flex-col items-start space-y-14 w-full bg-white px-4 py-4 md:px-10">
        {/* payment details */}
        <div className="w-full space-y-4">
          <h3 className="font-urbanist font-semibold text-black1 text-xl md:text-2xl">
            Checkout
          </h3>

          <p className="font-urbanist font-semibold dark:text-white text-black text-sm block mb-4">
            Select payment gateway
          </p>

          {/* paypal */}
          <div className="flex flex-col md:flex-row space-y-2 space-x-0 md:space-x-2 md:space-y-0 mb-6 w-full">
            <div className="w-full md:basis-1/2">
              <MethodSelection
                PaymentMethodImage={PaypalImage}
                handlePaymentMethodSelection={handlePaymentMethodSelection}
                paymentMethod="paypal"
                selectedPaymentMethod={selectedPaymentMethod}
              />
            </div>
            <div className="w-full md:basis-1/2">
              <MethodSelection
                PaymentMethodImage={StripeLogo}
                handlePaymentMethodSelection={handlePaymentMethodSelection}
                paymentMethod="stripe"
                selectedPaymentMethod={selectedPaymentMethod}
              />
            </div>
          </div>
        </div>

        {/* order details */}
        <div className="space-y-4 w-full">
          <h2 className="font-urbanist font-semibold text-2xl md:text-3xl text-black1">
            Order Details
          </h2>
          <div className="flex items-center space-x-2 justify-between w-full">
            {/* image and title */}
            <div className="flex items-center flex-1 space-x-2">
              <div className="p-2 rounded-md bg-white shadow-md">
                <img
                  src={courseData?.imageUrl}
                  alt="course_img"
                  className="h-14 w-14 rounded-md object-contain"
                />
              </div>
              <div className="space-y-1">
                <p className="text-base dark:text-white text-black/80 font-urbanist font-semibold mb-2 flex flex-wrap line-clamp-2 max-h-12">
                  {courseData?.name}
                </p>
                {courseData?.grades[0]?.name && (
                  <span className="text-base dark:text-white text-black/80 font-urbanist font-semibold mb-2 flex flex-wrap line-clamp-2 max-h-5">
                    {addSuffixToNumber(courseData?.grades[0]?.name)} - Grade
                  </span>
                )}
              </div>
            </div>

            {/* author name  */}
            <div className="flex items-center space-x-2">
              {courseData?.avatar ? (
                <img
                  src={courseData?.avatar}
                  className="h-7 w-7 object-cover rounded-full"
                  alt="instructor_profile"
                />
              ) : (
                <UserCircleIcon className="h-7 w-7 dark:text-white text-gray20" />
              )}
              <span className="font-urbanist font-medium text-sm dark:text-white text-blue25">
                {courseData?.author}
              </span>
            </div>
          </div>
        </div>

        {selectedPaymentMethod === "stripe" ? (
          <CourseStripeCheckout
            courseId={courseData?._id}
            courseSlug={courseSlug}
          />
        ) : (
          <CoursePaypalCheckout
            amount={courseData?.cost}
            instructorId={courseData?.authorId}
            courseId={courseData?._id}
            courseSlug={courseSlug}
          />
        )}
      </div>

      {/* right */}
      <div className="w-full bg-gray9 px-4 py-6 md:py-10 md:px-10">
        <p className="font-urbanist font-semibold text-lg md:text-xl text-black1">
          Summary
        </p>
        {/* price breakdown */}
        <div className="flex flex-col space-y-2 items-start border-b border-b-gray-400 w-full py-2">
          {/* original price */}
          <div className="flex items-center space-x-2 justify-between w-full">
            <p className="font-urbanist font-normal text-gray4 text-sm">
              Original Price
            </p>
            <p className="font-urbanist font-normal text-sm text-gray4">
              {courseData?.cost?.toLowerCase() === "free" ||
              parseFloat(courseData?.cost || 0) === undefined ||
              parseFloat(courseData?.cost || 0) <= 0 ||
              parseFloat(courseData?.cost || 0) - (courseData?.discount || 0) <=
                0
                ? "Free"
                : new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(parseFloat(courseData?.cost))}
            </p>
          </div>
          {/* discounted price */}
          <div className="flex items-center space-x-2 justify-between w-full">
            <p className="font-urbanist font-normal text-gray4 text-sm">
              Discount Price
            </p>
            <p className="font-urbanist font-normal text-sm text-gray4">
              -{" "}
              {courseData?.discount?.toLowerCase() === "free" ||
              parseFloat(courseData?.discount || 0) === undefined ||
              parseFloat(courseData?.discount || 0) <= 0 ||
              parseFloat(courseData?.discount || 0) -
                (courseData?.discount || 0) <=
                0
                ? "0"
                : new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(parseFloat(courseData?.discount))}
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-2 justify-between mt-1">
          <p className="font-urbanist font-semibold text-black1 text-sm">
            Total
          </p>
          <p className="font-urbanist font-semibold text-black1 text-sm">
            {courseData?.cost?.toLowerCase() === "free" ||
            parseFloat(courseData?.cost || 0) === undefined ||
            parseFloat(courseData?.cost || 0) <= 0 ||
            parseFloat(courseData?.cost || 0) - (courseData?.discount || 0) <= 0
              ? "Free"
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(
                  parseFloat(courseData?.cost) -
                    parseFloat(courseData?.discount || 0)
                )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseCheckout;
