import React, { useContext, useState } from "react";
import ShuttleImage from "../../images/shuttle.png";
import MenuIcon from "../../images/menuIcon.svg";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user";
import { AppDataContext } from "../../context/appData";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import DarkModeToggle from "../DarkModeToggle";
import { DarkModeContext } from "../../context/darkModeContext";

const Header = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const {
    state: { darkMode },
  } = useContext(DarkModeContext);

  // user data context
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleNavigation = () => {
    setMenuOpen(false);
    if (Object.keys(userInfo).length) {
      if (userInfo?.role?.includes("instructor")) {
        navigate("/instructor/dashboard");
      } else {
        navigate("/dashboard");
      }
    } else navigate("/login");
  };

  return (
    <div className="relative flex items-center px-4 lg:px-16 py-4 justify-between dark:bg-darkBgColor1 bg-white space-x-2">
      {/* logo */}

      <div className="flex items-center space-x-2 cursor-pointer">
        {Object.keys(appData).length ? (
          <img
            loading="lazy"
            src={
              darkMode
                ? appData?.homePageData?.darkModeLogoUrl
                : appData?.homePageData?.logoUrl
            }
            alt="logo"
            className="h-16 object-contain mb-2"
            onClick={() => navigate("/")}
          />
        ) : (
          <span>Loading...</span>
        )}
      </div>

      {/* mobile menu icon */}
      <div
        className="p-4 flex items-center justify-center lg:hidden rounded-lg cursor-pointer"
        style={{
          background: darkMode
            ? "transparent"
            : "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
        }}
        onClick={toggleMenu}
      >
        <img src={MenuIcon} alt="menu" className="" />
      </div>

      {/* mobile menu */}
      <div
        className={`${
          menuOpen
            ? "opacity-100 translate-x-400 z-[100] border-b border-blue40 dark:border-purple3 "
            : "opacity-0 -translate-x-[2000px] -z-[1]"
        } transition-all ease-in-out duration-500 px-4 py-5 absolute top-24 right-0 dark:bg-purple2 bg-blue-900 flex flex-col items-center justify-center lg:hidden rounded-bl-lg rounded-br-lg w-full space-y-6`}
      >
        {/* <div className="flex flex-col items-center space-y-6"> */}
        {!Object.keys(userInfo).length && (
          <button
            className="flex items-center py-3 px-8 rounded-[90px] font-urbanist text-sm text-white font-bold cursor-pointer"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            onClick={() => {
              setMenuOpen(false);
              navigate("/signup");
            }}
          >
            <img src={ShuttleImage} alt="shuttle" className="mr-2" />
            Get Started
          </button>
        )}
        <span
          className="text-sm font-semibold font-inter text-white cursor-pointer whitespace-nowrap truncate max-w-xs"
          onClick={handleNavigation}
        >
          {Object.keys(userInfo)?.length ? userInfo?.name : "Log in"}
        </span>

        <DarkModeToggle />
      </div>

      {/* join button */}
      <div className="hidden lg:flex items-center space-x-4">
        <div
          className="flex items-center text-sm font-semibold font-inter dark:text-white text-blue2 cursor-pointer"
          onClick={handleNavigation}
        >
          {Object.keys(userInfo)?.length ? (
            <p className="flex items-center">
              <span className="whitespace-nowrap truncate max-w-[90px]">
                {userInfo?.name}
              </span>
              {userInfo?.profileImage?.length ? (
                <img
                  src={userInfo?.profileImage}
                  alt="profile_pic"
                  className="h-10 w-10 object-cover rounded-full ml-2"
                />
              ) : (
                <UserCircleIcon className="h-10 dark:text-white text-blue2 ml-2" />
              )}
            </p>
          ) : (
            "Log in"
          )}
        </div>
        {!Object.keys(userInfo).length && (
          <button
            className="flex items-center py-3 px-8 rounded-[90px] font-raleway text-sm text-white font-bold cursor-pointer"
            style={{
              background:
                "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            onClick={() => navigate("/signup")}
          >
            <img src={ShuttleImage} alt="shuttle" className="mr-2" />
            Get Started
          </button>
        )}

        <DarkModeToggle />
      </div>
    </div>
  );
};

export default Header;
