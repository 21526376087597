import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/user";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { CourseFields } from "../utils/staticData";
import ChooseCategoryCard from "../components/CreateCourseComponents/CourseChooseCategory/ChooseCategoryCard/ChooseCategoryCard";
import { AppDataContext } from "../context/appData";
import { fetchPageData } from "../utils/network-requests";
import AuthorDetails from "../components/AdminPageComponents/AuthorDetails";
import AddCourseInformation from "../components/CreateCourseComponents/AddCourseInformation/AddCourseInformation";
import {
  ArrowLeftIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { getURLs } from "../urlConfig";
import CustomModal from "../components/CustomModal/CustomModal";
import AddFileHandler from "../components/AddFileHandler";

const AddCourseDetailsWithCsv = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);
  const {
    state: { userInfo, userUpdated },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    ...CourseFields,
  });
  const [file, setFile] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [courseImage, setCourseImage] = useState(null);
  const [error, setError] = useState({ show: false, message: "" });
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const removeSelection = () => {
    setFile(null);
  };

  const handleSave = async () => {
    if (formData?.urlSlug?.trim() && file) {
      setError({ show: false, message: "" });
      if (disableSaveButton) return;
      setDisableSaveButton(true);
      const bookFormData = new FormData();
      bookFormData.append("upload_file", file);

      let imageUrl;
      // upload images
      if (courseImage) {
        const imageSignedUrl = await axios.post(
          getURLs("image-upload-url"),
          { imageFor: "courseImage" },
          {
            headers: { "auth-token": userInfo?.authToken },
            withCredentials: true,
          }
        );
        await fetch(imageSignedUrl?.data?.url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: courseImage,
        });

        imageUrl = imageSignedUrl?.data?.url?.split("?")[0];
      }

      const updatedFormData = { ...formData };
      // adding image url to formdata
      if (imageUrl) {
        updatedFormData.imageUrl = imageUrl;
      }

      Object.keys(updatedFormData).forEach((key) => {
        bookFormData.append(key, updatedFormData[key]);
      });

      axios
        .post(getURLs("add-course-csv"), bookFormData, {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then(() => {
          setShowSuccessModal(true);
          setDisableSaveButton(false);
          setFile(null);
          setCourseImage(null);
          setFormData({ ...CourseFields });
        })
        .catch((err) => {
          console.log(err);
          if (
            err?.response?.status === 400 ||
            err?.response?.status === 401 ||
            err?.response?.status === 500
          )
            setError({
              show: true,
              message: err?.response?.data?.message || "Something went wrong",
            });

          setDisableSaveButton(true);
        });
    } else {
      if (!formData?.title) {
        setError({ show: true, message: "Course name cannot be empty" });
      } else if (!formData?.urlSlug) {
        setError({ show: true, message: "Course slug cannot be empty" });
      }
    }
  };

  useEffect(() => {
    if (userUpdated) {
      if (
        !Object.keys(userInfo || {}).length ||
        (!userInfo?.role?.includes("instructor") &&
          !userInfo?.role?.includes("admin"))
      ) {
        navigate("/", { replace: true });
      }
      setLoading(false);
    }
  }, [userInfo, userUpdated, navigate]);

  // fetch options for creating course
  useEffect(() => {
    if (!Object.keys(appData?.instructorCreateCoursePage || {}).length > 0) {
      fetchPageData("instructorCreateCoursePage")
        .then((pageData) => {
          initializeAppData({ instructorCreateCoursePage: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.instructorCreateCoursePage]);

  return loading ? (
    <div className="absolute top-0 bottom-0 right-0 left-0 z-50 bg-black/10 flex items-center justify-center">
      <Loader />
    </div>
  ) : (
    <div className="flex-1">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center space-x-2 mb-8 px-4">
          <ArrowLeftIcon
            className="h-5 w-5 text-black dark:text-white cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2 className="font-urbanist text-black dark:text-white font-semibold text-xl sm:text-2xl md:text-3xl">
            Add Course Information
          </h2>
        </div>
        <div className="flex flex-col space-y-2 mt-4">
          <div className="flex flex-col space-y-1 px-4">
            <p className="ont-urbanist font-medium text-sm text-black1 dark:text-white block">
              Course name
            </p>
            <input
              className="dark:bg-purple14 dark:border-purple26 disabled:bg-gray-100 text-sm rounded-lg outline-none border border-gray-39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray-500 placeholder:dark:text-gray-200 dark:text-gray-500"
              value={formData?.title}
              onChange={handleInputChange}
              name="title"
              placeholder="Course Title"
            />
          </div>
          <div className="flex flex-col space-y-1 px-4">
            <p className="ont-urbanist font-medium text-sm text-black1 dark:text-white block">
              Course Category
            </p>
            <div className="flex flex-wrap items-center gap-4 mb-6">
              {appData?.instructorCreateCoursePage?.selectCategorySectionData?.availableCategories?.map(
                (data) => (
                  <ChooseCategoryCard
                    category={data.name}
                    key={data.id}
                    selectedCategory={formData?.category}
                    handleClick={handleInputChange}
                  />
                )
              )}
            </div>
          </div>
          <div className="flex flex-col space-y-1 px-4">
            <p className="ont-urbanist font-medium text-sm text-black1 dark:text-white block">
              Course url title
            </p>
            <input
              className="dark:bg-purple14 dark:border-purple26 disabled:bg-gray-100 text-sm rounded-lg outline-none border border-gray-39 px-4 py-2 font-urbanist font-medium placeholder:text-gray30 text-gray-500 placeholder:dark:text-gray-200 dark:text-gray-500"
              value={formData?.urlSlug || ""}
              onChange={handleInputChange}
              name="urlSlug"
            />
          </div>

          <AddCourseInformation
            formData={formData}
            setFormData={setFormData}
            handleChange={handleInputChange}
            handleSave={handleSave}
            availableGradesData={
              appData?.instructorCreateCoursePage?.addCourseFormData
                ?.availableGradesData
            }
            setImageFile={setCourseImage}
            showGradeInput={false}
            showSaveButton={false}
          />

          {/* author information */}
          {userInfo?.role?.includes("admin") && (
            <div className="px-4">
              <AuthorDetails formData={formData} setFormData={setFormData} />
            </div>
          )}

          <div className="flex flex-col space-y-2 px-4">
            <p className="font-urbanist text-black dark:text-white font-semibold text-sm">
              Upload Course CSV
            </p>

            <AddFileHandler
              handleFileChange={handleFileChange}
              inputId="input-file-upload-course-csv"
              uploadComponentTitle={"Only CSV files"}
              acceptedTypes={".csv"}
              title={"Upload Course CSV"}
            />

            {file && (
              <div className="bg-gray-300 rounded-md px-4 py-2 w-max flex items-center justify-between space-x-5">
                <p className="font-urbanist font-medium text-gray-600 dark:text-white">
                  Uploaded File: {file?.name}
                </p>
                <XMarkIcon
                  className="text-gray-600 dark:text-white h-5 w-5 cursor-pointer"
                  onClick={removeSelection}
                />
              </div>
            )}
          </div>

          <div
            className="self-end rounded-lg mr-4"
            style={{
              background:
                !disableSaveButton &&
                "linear-gradient(#270046, #270046) padding-box, linear-gradient(270.81deg, #B927FF 0%, #6164FF 47.26%, #06CCFF 100%) border-box",
              border: "1px solid transparent",
            }}
          >
            <button
              className="px-20 py-2 bg-white disabled:bg-gray-100 rounded-lg"
              disabled={disableSaveButton}
              onClick={handleSave}
            >
              <p
                className="text-sm font-urbanist font-semibold"
                style={{
                  backgroundImage:
                    "linear-gradient(90deg, #2CD8D5 0%, #B1ACFF 56%, #FF96A4 100%)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                Save
              </p>
            </button>
          </div>

          {error?.show && (
            <p className="my-4 block text-sm font-urbanist font-medium text-red-500 px-4">
              {error?.message}
            </p>
          )}

          <CustomModal show={showSuccessModal} centered>
            {showSuccessModal && (
              <div className="relative flex flex-col space-y-1 py-4 px-6 w-full">
                <div className="absolute top-2 right-2">
                  <XCircleIcon
                    className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                    onClick={() => setShowSuccessModal(false)}
                  />
                </div>
                <h4 className="text-sm font-inter font-medium flex-1 text-center text-black mx-auto py-5">
                  Course uploaded successfully
                </h4>
              </div>
            )}
          </CustomModal>
        </div>
      </div>
    </div>
  );
};

export default AddCourseDetailsWithCsv;
