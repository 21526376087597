import React, { useState } from "react";
import ReactPlayer from "react-player";
import ShowPromotionDetailsModal from "../../../modal/ShowPromotionDetailsModal";
import { PlayIcon } from "@heroicons/react/24/outline";
import "./PromotionCardsContainer.css";

const PromotionCardsContainer = ({ promotionImages, promotionVideos }) => {
  const [openShowDetailsPopup, setOpenShowDetailsPopup] = useState(false);
  const [openedCardDetails, setOpenedCardDetails] = useState({
    type: "",
    value: "",
    toBedirect: false,
    redirectLink: "",
  });

  const handlePromotionCardClick = (details, type) => {
    setOpenedCardDetails({ value: details, type });
    setOpenShowDetailsPopup(true);
  };

  const handleModalClose = () => {
    setOpenedCardDetails({
      type: "",
      value: "",
      toBedirect: false,
      redirectLink: "",
    });
    setOpenShowDetailsPopup(false);
  };

  return (
    <div className="grid grid-cols-2 xl:grid-cols-1 gap-2 mb-2 xl:mb-0 h-32 xl:h-full">
      {/* images container */}
      <div className="border border-gray-200 dark:border-purple15 w-full rounded-lg flex items-center xl:flex-col xl:items-start gap-2 px-1 py-1">
        <div className="basis-2/3 w-full h-full flex items-center xl:flex-col xl:items-start gap-2">
          <div
            className={`basis-1/2 ${
              promotionImages?.[0]?.length ? "" : "bg-gray-50 rounded-lg"
            } w-full h-full items-center flex flex-col justify-center`}
          >
            {promotionImages?.[0]?.length ? (
              <div
                className="rounded-lg h-28 xl:h-[84px] w-full cursor-pointer border border-gray-300 dark:border-purple15"
                onClick={() =>
                  handlePromotionCardClick(promotionImages[0], "img")
                }
              >
                <img
                  src={promotionImages?.[0]}
                  alt="promotion"
                  className="h-28 xl:h-[82px] w-full object-cover rounded-lg"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`basis-1/2 ${
              promotionImages?.[1]?.length ? "" : "bg-gray-100 rounded-lg"
            } w-full h-full items-center flex flex-col justify-center`}
          >
            {promotionImages?.[1]?.length ? (
              <div
                className="rounded-lg h-28 xl:h-[84px] w-full cursor-pointer border border-gray-300 dark:border-purple15"
                onClick={() =>
                  handlePromotionCardClick(promotionImages[1], "img")
                }
              >
                <img
                  src={promotionImages?.[1]}
                  alt="promotion"
                  className="h-28 xl:h-[82px] w-full object-cover rounded-lg"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={`basis-1/3 ${
            promotionImages?.[2]?.length ? "" : "bg-gray-200 rounded-lg"
          } w-full h-full items-center flex flex-col justify-center`}
        >
          {promotionImages?.[2]?.length ? (
            <div
              className="rounded-lg h-28 xl:h-[84px] w-full cursor-pointer border border-gray-300 dark:border-purple15"
              onClick={() =>
                handlePromotionCardClick(promotionImages[2], "img")
              }
            >
              <img
                src={promotionImages?.[2]}
                alt="promotion"
                className="h-28 xl:h-[82px] w-full object-cover rounded-lg"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* video container */}
      <div className="video_preview border border-gray-200 dark:border-purple15 w-full rounded-lg flex items-center xl:flex-col xl:items-start gap-2 px-1 py-1">
        <div className="basis-2/3 w-full h-full flex items-center xl:flex-col xl:items-start gap-2">
          <div
            className={`basis-1/2 ${
              promotionVideos?.[0]?.length ? "" : "bg-gray-50 rounded-lg"
            } w-full h-full items-center flex flex-col justify-center`}
          >
            {promotionVideos?.[0]?.length ? (
              <div className="h-28 xl:h-[84px] w-full cursor-pointer border border-gray-300 dark:border-purple15 relative">
                <ReactPlayer
                  url={promotionVideos[0]}
                  width="100%"
                  height="100%"
                  playing={false}
                  muted={true}
                />
                {/* Overlay div to open modal */}
                <div
                  className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-0 hover:bg-opacity-20 flex justify-center items-center"
                  onClick={() =>
                    handlePromotionCardClick(promotionVideos[0], "vid")
                  }
                >
                  <button className="h-8 w-8 rounded-full flex flex-col justify-center items-center bg-black">
                    <PlayIcon className="h-3 w-3 text-white" />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`basis-1/2 ${
              promotionVideos?.[1]?.length ? "" : "bg-gray-100 rounded-lg"
            } w-full h-full items-center flex flex-col justify-center`}
          >
            {promotionVideos?.[1]?.length ? (
              <div className="h-28 xl:h-[84px] w-full cursor-pointer border border-gray-300 dark:border-purple15 relative">
                <ReactPlayer
                  url={promotionVideos[1]}
                  width="100%"
                  height="100%"
                  playing={false}
                  muted={true}
                />
                {/* Overlay div to open modal */}
                <div
                  className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-0 hover:bg-opacity-20 flex justify-center items-center"
                  onClick={() =>
                    handlePromotionCardClick(promotionVideos[1], "vid")
                  }
                >
                  <button className="h-8 w-8 rounded-full flex flex-col justify-center items-center bg-black">
                    <PlayIcon className="h-3 w-3 text-white" />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={`basis-1/3 ${
            promotionVideos?.[2]?.length ? "" : "bg-gray-200 rounded-lg"
          } w-full h-full items-center flex flex-col justify-center`}
        >
          {promotionVideos?.[2]?.length ? (
            <div className="h-28 xl:h-[84px] w-full cursor-pointer border border-gray-300 dark:border-purple15 relative">
              <ReactPlayer
                url={promotionVideos[2]}
                width="100%"
                height="100%"
                playing={false}
                muted={true}
              />
              {/* Overlay div to open modal */}
              <div
                className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-0 hover:bg-opacity-20 flex justify-center items-center"
                onClick={() =>
                  handlePromotionCardClick(promotionVideos[2], "vid")
                }
              >
                <button className="h-8 w-8 rounded-full flex flex-col justify-center items-center bg-black">
                  <PlayIcon className="h-3 w-3 text-white" />
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {openShowDetailsPopup && (
        <ShowPromotionDetailsModal
          showModal={openShowDetailsPopup}
          handleClose={handleModalClose}
          promotionDetails={openedCardDetails}
        />
      )}
    </div>
  );
};

export default PromotionCardsContainer;
