import React, { useContext, useState } from "react";
import axios from "axios";
import { UserContext } from "../../context/user";
import { getURLs } from "../../urlConfig";
import {
  getStripe,
  getSuccessAndErrorUrlForCoursePayments,
} from "../../utils/utils";

const CourseStripeCheckout = ({ courseId, courseSlug }) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const handleStripeCheckoutClick = async () => {
    if (loading) return;
    setLoading(true);

    const stripeClient = await getStripe();
    const { successUrl, errorUrl } =
      getSuccessAndErrorUrlForCoursePayments(courseSlug);
    // session id of the checkout
    const response = await axios.get(
      `${getURLs("stripe-checkout")}/course/create-session`,
      {
        headers: {
          "auth-token": userInfo?.authToken,
        },
        params: { courseId, successUrl, errorUrl },
      }
    );

    await stripeClient?.redirectToCheckout({
      sessionId: response?.data?.sessionId,
    });
  };

  return (
    <button
      className="rounded-lg border-none disabled:bg-blue-300 text-white text-sm md:text-base font-urbanist font-bold w-full text-center px-10 py-2 mb-5"
      style={{
        background:
          "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
      }}
      disabled={loading}
      onClick={handleStripeCheckoutClick}
    >
      {loading ? "Loading..." : "Proceed to checkout"}
    </button>
  );
};

export default CourseStripeCheckout;
