import React, { useContext, useState } from "react";
import ChooseTimeline from "./ChooseTimeline/ChooseTimeline";
import PaypalImage from "../../../images/paypal.svg";
import StripeLogo from "../../../images/stripe.svg";
import MethodSelection from "./ChooseTimeline/MethodSelection/MethodSelection";
import { DarkModeContext } from "../../../context/darkModeContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../../context/user";
import SubscriptionPaypalCheckout from "../../PaypalCheckout/SubscriptionPaypalCheckout";
import PaymentStatusWrapper from "../../Layout/PaymentStatusWrapper";
import SubscriptionStripeCheckout from "../../StripeCheckout/SubscriptionStripeCheckout";

const PaymentSection = ({
  planName,
  planId,
  usedAsPopup = false,
  priceForSubscription,
  closePopupHandler,
  setSubscriptionValue,
  subscriptionValue,
}) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);
  // selected payment method state
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("paypal");
  // subscription timeline
  const [subscriptionTimeline, setSubscriptionTimeline] = useState("monthly");

  const handlePaymentMethodSelection = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  // checkout
  const goToCheckout = () => {
    if (selectedPaymentMethod === "paypal") {
      setSelectedPaymentMethod("stripe");
    } else {
      setSelectedPaymentMethod("paypal");
    }
  };

  return (
    <PaymentStatusWrapper>
      <div
        className={`flex flex-col items-start rounded-xl ${
          usedAsPopup
            ? ""
            : "max-w-sm border border-gray44 dark:border-purple15 shadow-xl"
        } dark:bg-purple16 w-full px-5 py-5`}
      >
        {usedAsPopup && (
          <div className="w-full flex items-center justify-end mb-4">
            <XMarkIcon
              className="dark:text-white text-gray-500 h-5 w-6 cursor-pointer"
              onClick={closePopupHandler}
            />
          </div>
        )}

        <div className="w-full flex items-center justify-between space-x-4 mb-4">
          <h3 className="text-blue25 text-base dark:text-white font-urbanist font-semibold block">
            Payment
          </h3>

          {usedAsPopup && (
            <span className="text-blue25 text-base dark:text-white font-urbanist font-semibold block">{`$ ${priceForSubscription}`}</span>
          )}
        </div>

        <div className="flex items-center space-x-4 mb-6">
          {/* select timeline */}
          <ChooseTimeline
            setSubscriptionTimeline={
              usedAsPopup ? setSubscriptionValue : setSubscriptionTimeline
            }
            subscriptionTimeline={
              usedAsPopup ? subscriptionValue : subscriptionTimeline
            }
          />
        </div>

        <p className="font-urbanist font-semibold dark:text-white text-black text-sm block mb-4">
          Select payment gateway
        </p>

        {/* paypal */}
        <div className="flex flex-col space-y-2 mb-6 w-full">
          <MethodSelection
            PaymentMethodImage={PaypalImage}
            handlePaymentMethodSelection={handlePaymentMethodSelection}
            paymentMethod="paypal"
            selectedPaymentMethod={selectedPaymentMethod}
          />

          <MethodSelection
            PaymentMethodImage={StripeLogo}
            handlePaymentMethodSelection={handlePaymentMethodSelection}
            paymentMethod="stripe"
            selectedPaymentMethod={selectedPaymentMethod}
          />
        </div>

        {!usedAsPopup ? (
          <div
            className="px-4 py-2 cursor-pointer rounded-lg text-center w-full"
            style={{
              background: !darkMode
                ? "linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box"
                : "linear-gradient(#2E0053, #2E0053) padding-box, linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF) border-box",
              border: "1px solid transparent",
            }}
            onClick={goToCheckout}
          >
            <span
              className="text-sm font-urbanist font-medium text-center"
              style={{
                backgroundImage:
                  "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), linear-gradient(0deg, #2898FF, #2898FF)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              Change payment menthod
            </span>
          </div>
        ) : selectedPaymentMethod === "stripe" ? (
          <SubscriptionStripeCheckout
            closePopupHandler={closePopupHandler}
            planId={planId}
            planName={planName}
            subscriptionTimeline={subscriptionTimeline}
            userInfo={userInfo}
          />
        ) : (
          <SubscriptionPaypalCheckout
            planName={planName}
            planId={planId}
            subscriptionAmount={priceForSubscription}
            subscriptionTimeline={subscriptionTimeline}
            closePopupHandler={closePopupHandler}
          />
        )}
      </div>
    </PaymentStatusWrapper>
  );
};

export default PaymentSection;
