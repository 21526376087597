import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import { UserContext } from "../../context/user";
import { AdminDashboardTabs } from "../../utils/staticData";
import { AppDataContext } from "../../context/appData";
import { getURLs } from "../../urlConfig";
import axios from "axios";
import { logoutUser } from "../../context/user/actions";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const AdminLayout = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabClick = (tabID, tabTitle) => {
    ReactGA.event({
      category: "Dashboard Tab Click",
      action: `Clicked on ${tabTitle} tab`,
      label: `${tabTitle} Rendered`,
    });
    if (tabID === 1) {
      navigate("/admin/dashboard");
    } else if (tabID === 2) {
      navigate("/admin/dashboard/all-courses");
    } else if (tabID === 3) {
      navigate("/admin/dashboard/instructor-accounts");
    } else if (tabID === 4) {
      navigate("/admin/dashboard/blog-requests");
    } else if (tabID === 5) {
      navigate("/admin/dashboard/all-accounts");
    } else if (tabID === 6) {
      navigate("/admin/dashboard/add-course-csv");
    } else if (tabID === 7) {
      navigate("/admin/dashboard/check-course-status");
    } else if (tabID === 8) {
      navigate("/admin/dashboard/add-details-csv-course");
    } else if (tabID === 9) {
      navigate("/admin/dashboard/paid-accounts");
    } else if (tabID === 10) {
      navigate("/create-course-csv");
    } else if (tabID === 11) {
      navigate("/admin/dashboard/all-dynamic-courses");
    } else if (tabID === 12) {
      navigate("/admin/dashboard/usage-metrics");
    } else if (tabID === 13) {
      navigate("/admin/dashboard/usage-reports");
    }
  };

  const handleLogout = () => {
    axios
      .post(
        getURLs("logoutUrl"),
        {},
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("persist", false);
          sessionStorage.clear();
          logoutUser();
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const currentRoute = location.pathname;
    if (currentRoute === "/admin/dashboard") {
      setSelectedTab(1);
    } else if (currentRoute === "/admin/dashboard/all-courses") {
      setSelectedTab(2);
    } else if (currentRoute === "/admin/dashboard/instructor-accounts") {
      setSelectedTab(3);
    } else if (currentRoute === "/admin/dashboard/blog-requests") {
      setSelectedTab(4);
    } else if (currentRoute === "/admin/dashboard/all-accounts") {
      setSelectedTab(5);
    } else if (currentRoute === "/admin/dashboard/add-course-csv") {
      setSelectedTab(6);
    } else if (currentRoute === "/admin/dashboard/check-course-status") {
      setSelectedTab(7);
    } else if (currentRoute === "/admin/dashboard/add-details-csv-course") {
      setSelectedTab(8);
    } else if (currentRoute === "/admin/dashboard/paid-accounts") {
      setSelectedTab(9);
    } else if (currentRoute === "/admin/dashboard/all-dynamic-courses") {
      setSelectedTab(11);
    } else if (currentRoute === "/admin/dashboard/usage-metrics") {
      setSelectedTab(12);
    } else if (currentRoute === "/admin/dashboard/usage-reports") {
      setSelectedTab(13);
    }
  }, [location.pathname]);
  return (
    <>
      <Helmet>
        <title>Brainjee - Admin Dashboard</title>
        <link rel="canonical" href="https://www.brainjee.com/admin" />
      </Helmet>

      <div className="flex flex-col min-h-screen h-full bg-gray-100 dark:bg-darkBgColor1">
        <DashboardHeader
          pageData={appData?.homePageData}
          userInfo={userInfo}
          selectedTab={selectedTab}
          handleLogout={handleLogout}
          handleTabClick={handleTabClick}
          TabsData={AdminDashboardTabs}
        />
        <div className="flex h-full">
          <div className="hidden lg:basis-1/4 w-full h-full lg:inline-flex">
            <DashboardSidebar
              userInfo={userInfo}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
              TabsData={AdminDashboardTabs}
            />
          </div>
          <div className="lg:basis-3/4 rounded-md w-full px-2 lg:pr-2">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
