import React from "react";
import OverviewInfoCard from "./OverviewInfoCard/OverviewInfoCard";
import TotalOrders from "./TotalOrders/TotalOrders";
import InstructorAllCourses from "../InstructorAllCourses/InstructorAllCourses";
import ShowEnrolledStudents from "./ShowEnrolledStudents/ShowEnrolledStudents";
import { getMonthlyEarnings } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

const AccountOverview = ({ userInfo, instructorCourses }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col space-y-4 py-2">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 bg-white dark:bg-purple14 px-8 py-4 rounded-lg">
        <OverviewInfoCard
          title={`Earnings this month`}
          value={`$${getMonthlyEarnings(userInfo?.overview?.monthlyEarnings)}`}
        />
        <OverviewInfoCard
          title={`Account Balance`}
          value={`$${userInfo?.overview?.accountBalance || 0}`}
        />
        <OverviewInfoCard
          title={`Life time sales`}
          value={userInfo?.overview?.totalSales || 0}
        />
      </div>

      {/* orders  */}
      <TotalOrders
        handleViewAllClick={() => navigate("/instructor/dashboard/oders")}
      />

      {/* courses table */}
      <InstructorAllCourses instructorCourses={instructorCourses} />

      {/* enrolled students */}
      <ShowEnrolledStudents
        showViewAllButton
        handleViewAllClick={() => navigate("/instructor/dashboard/students")}
      />
    </div>
  );
};

export default AccountOverview;
