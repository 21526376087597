import React, { useContext, useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import DashboardSidebar from "../DashboardSidebar";
import { AppDataContext } from "../../context/appData";
import { UserContext } from "../../context/user";
import ReactGA from "react-ga4";
import axios from "axios";
import { getURLs } from "../../urlConfig";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { InstructorDashboardTabsData } from "../../utils/staticData";
import { InstructorDataContext } from "../../context/instructorData";
import { fetchPageData } from "../../utils/network-requests";
import { Helmet } from "react-helmet-async";

const InstructorDashboardLayout = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);
  const {
    state: { userInfo },
    logoutUser,
  } = useContext(UserContext);
  const { getAllCourses } = useContext(InstructorDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(1);

  const handleLogout = () => {
    axios
      .post(
        getURLs("logoutUrl"),
        {},
        {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("persist", false);
          sessionStorage.clear();
          logoutUser();
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabClick = (tabID, tabTitle) => {
    ReactGA.event({
      category: "Dashboard Tab Click",
      action: `Clicked on ${tabTitle} tab`,
      label: `${tabTitle} Rendered`,
    });
    if (tabID === 1) {
      navigate("/instructor/dashboard");
    } else if (tabID === 2) {
      navigate("/instructor/dashboard/all-courses");
    } else if (tabID === 3) {
      navigate("/instructor/dashboard/quiz");
    } else if (tabID === 4) {
      navigate("/instructor/dashboard/students");
    } else if (tabID === 5) {
      navigate("/instructor/dashboard/orders");
    } else if (tabID === 6) {
      navigate("/instructor/dashboard/earnings");
    } else if (tabID === 7) {
      navigate("/instructor/dashboard/payouts");
    } else if (tabID === 8) {
      navigate("/instructor/dashboard/my-blogs");
    } else if (tabID === 9) {
      navigate("/create-course-csv");
    }
  };

  useEffect(() => {
    const currentRoute = location.pathname;
    if (currentRoute === "/instructor/dashboard") {
      setSelectedTab(1);
    } else if (currentRoute === "/instructor/dashboard/all-courses") {
      setSelectedTab(2);
    } else if (currentRoute === "/instructor/dashboard/quiz") {
      setSelectedTab(3);
    } else if (currentRoute === "/instructor/dashboard/students") {
      setSelectedTab(4);
    } else if (currentRoute === "/instructor/dashboard/orders") {
      setSelectedTab(5);
    } else if (currentRoute === "/instructor/dashboard/earnings") {
      setSelectedTab(6);
    } else if (currentRoute === "/instructor/dashboard/payouts") {
      setSelectedTab(7);
    } else if (currentRoute === "/instructor/dashboard/my-blogs") {
      setSelectedTab(8);
    }
  }, [location.pathname]);

  // fetching all the courses for the instructor
  useEffect(() => {
    // getting instructor info
    if (
      userInfo?.role?.includes("instructor") ||
      userInfo?.role?.includes("admin")
    ) {
      // getting the courses
      axios
        .get(getURLs("get-instructor-courses"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          getAllCourses(res?.data?.allCourses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!Object.keys(appData?.instructorCreateCoursePage || {}).length > 0) {
      fetchPageData("instructorCreateCoursePage")
        .then((pageData) => {
          initializeAppData({ instructorCreateCoursePage: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appData.instructorCreateCoursePage]);

  return (
    <>
      <Helmet>
        <title>Brainjee - Instructor Dashboard</title>
        <link
          rel="canonical"
          href="https://www.brainjee.com/instructor/new-dashboard"
        />
      </Helmet>
      <div className="flex flex-col min-h-screen h-full bg-gray-100 dark:bg-darkBgColor1">
        <DashboardHeader
          pageData={appData?.homePageData}
          userInfo={userInfo}
          selectedTab={selectedTab}
          handleLogout={handleLogout}
          handleTabClick={handleTabClick}
          TabsData={InstructorDashboardTabsData}
        />
        <div className="flex h-full">
          <div className="hidden lg:basis-1/4 w-full h-full lg:inline-flex">
            <DashboardSidebar
              userInfo={userInfo}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
              TabsData={InstructorDashboardTabsData}
            />
          </div>
          <div className="lg:basis-4/5 relative rounded-md w-full px-2 lg:pr-2">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructorDashboardLayout;
