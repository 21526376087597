import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseDetailsSidebar from "../components/CourseDetailsSectionContent/CourseDetailsSidebar/CourseDetailsSidebar";
import { UserContext } from "../context/user";
import axios from "axios";
import { getURLs } from "../urlConfig";
import CustomModal from "../components/CustomModal/CustomModal";
import { enrollInCourse } from "../utils/network-requests";
import PaymentStatusWrapper from "../components/Layout/PaymentStatusWrapper";
import CoursesHero from "../components/Hero/CoursesHero/CoursesHero";
import DynamicCourseOverview from "../components/CourseDetailsSectionContent/DynamicCourseOverview/DynamicCourseOverview";

const DynamicCourseDetails = () => {
  const {
    state: { userInfo, userBotInfo },
    updateUserBotInfo,
  } = useContext(UserContext);

  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({});
  const [enrollButtonText, setEnrollButtonText] = useState("Enroll");
  const { courseId } = useParams();

  const [expanded, setExpanded] = useState("");
  const [sectionContent, setSectionContent] = useState({});

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);

  const handleCourseEnroll = () => {
    if (
      parseFloat(courseData?.cost || 0) === undefined ||
      parseFloat(courseData?.cost || 0) <= 0 ||
      parseFloat(courseData?.cost || 0) - (courseData?.discount || 0) <= 0
    ) {
      const studentInfo = {
        name: userInfo?.name,
        email: userInfo?.email,
        image: userInfo?.profileImage,
        country: "India",
      };
      enrollInCourse(
        userInfo?.authToken,
        courseData?._id,
        studentInfo,
        courseData?.author_id
      )
        .then(() => {
          setEnrollButtonText("Enrolled");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate(`/course/checkout`, {
        state: {
          courseData,
          courseSlug: courseId,
        },
      });
    }
  };

  useEffect(() => {
    if (Object.keys(userInfo || {}).length > 0) {
      setLoading(true);
      // fetch course information from course id
      axios
        .get(getURLs("dynamic-book-info"), {
          headers: {
            "auth-token": userInfo.authToken,
          },
          params: { bookSlug: courseId },
        })
        .then((response) => {
          const bookInfo = response.data?.bookDetails;
          setCourseData(bookInfo);
          setExpanded(bookInfo?.grades[0]?.chapters?.[0]?._id);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setErrorLoading(true);
          setLoading(false);
        });
    }
  }, [userInfo, courseId]);

  // check for bot id exist for the user
  useEffect(() => {
    if (Object.keys(userInfo)?.length && !Object.keys(userBotInfo)?.length) {
      const botInfo = sessionStorage.getItem("botInfo");
      if (botInfo) {
        updateUserBotInfo(JSON.parse(botInfo));
      }
    }
  }, [userInfo, userBotInfo]);

  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      Object.keys(courseData).length > 0
    ) {
      axios
        .get(getURLs("is-enrolled"), {
          params: {
            courseId: courseData?._id,
          },
          headers: {
            "auth-token": userInfo?.authToken,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res.data?.isEnrolledInCourse) {
            setEnrollButtonText("Enrolled");
          } else {
            setEnrollButtonText("Enroll");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [courseData, userInfo]);

  return (
    <PaymentStatusWrapper>
      <div className="bg-gray21 dark:bg-darkBgColor1 px-4 py-4">
        {!loading && !errorLoading ? (
          <>
            <div className="flex items-center justify-end mb-2">
              <button
                className="flex items-center px-5 py-2 font-urbanist font-semibold text-white text-sm border-none rounded-xl"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
                }}
                onClick={
                  enrollButtonText?.toLowerCase() === "enrolled"
                    ? () => {}
                    : handleCourseEnroll
                }
              >
                {enrollButtonText}
              </button>
            </div>
            <div className="bg-white dark:bg-darkBgColor1 py-2 flex flex-col-reverse lg:flex-row items-start border border-gray-100 dark:border-purple2 rounded-3xl">
              {/* course content table */}
              <div className="lg:basis-1/4 w-full px-2">
                <CourseDetailsSidebar
                  expanded={expanded}
                  setExpanded={setExpanded}
                  courseData={courseData}
                  sectionContent={sectionContent}
                  setSectionContent={setSectionContent}
                />
              </div>

              {Object.keys(sectionContent).length ? (
                <CoursesHero
                  tutor={"math"}
                  courseSearch={true}
                  searchValue={sectionContent?.name}
                />
              ) : (
                <DynamicCourseOverview
                  courseData={courseData}
                  sectionContent={sectionContent}
                  showCreateNoteButton={false}
                />
              )}
            </div>
          </>
        ) : !loading && errorLoading ? (
          <CustomModal show={!loading && errorLoading} centered>
            {!loading && errorLoading && (
              <div className="px-10 py-8 rounded-md bg-white dark:bg-purple14 dark:border dark:border-purple26">
                <p className="font-urbanist font-medium text-gray-600 dark:text-white">
                  Failed to load the course, please refresh the page
                </p>
              </div>
            )}
          </CustomModal>
        ) : (
          <CustomModal show={loading} centered>
            {loading && (
              <div className="px-10 py-8 rounded-md bg-white dark:bg-purple14 dark:border dark:border-purple26">
                <p className="font-urbanist font-medium text-gray-600 dark:text-white">
                  Please wait, loading course details
                </p>
              </div>
            )}
          </CustomModal>
        )}
      </div>
    </PaymentStatusWrapper>
  );
};

export default DynamicCourseDetails;
