import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../../context/user";
import { getURLs } from "../../../urlConfig";
import CustomModal from "../../CustomModal/CustomModal";

const ChangeSearchesLeftForUser = ({ open, userIdToCheck, handleClose }) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const [currentSearches, setCurrentSearches] = useState("");
  const [newSearches, setNewSearches] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [searchesUpdated, setSearchesUpdated] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });

  const handleSearchesModify = () => {
    if (
      open &&
      userInfo &&
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      userIdToCheck &&
      !disableButton
    ) {
      setDisableButton(true);
      axios
        .put(
          getURLs("update-user-searches"),
          {
            noOfSearches: parseInt(newSearches),
            userIdToUpdate: userIdToCheck,
          },
          {
            headers: {
              "auth-token": userInfo?.authToken,
            },
            withCredentials: true,
          }
        )
        .then(() => {
          setDisableButton(false);
          setSearchesUpdated(true);
          setTimeout(() => {
            handleCloseModal();
          }, [2000]);
        })
        .catch((err) => {
          console.log(err);
          setError({
            show: true,
            message: err?.message
              ? `Unable to update number of searches for user due to: ${err?.message}`
              : "Unable to update searches, please try later",
          });
          setTimeout(() => {
            setError({ show: false, message: "" });
          }, [2000]);
        });
    }
  };

  const handleCloseModal = () => {
    setNewSearches("");
    setCurrentSearches("");
    setDisableButton(false);
    setSearchesUpdated(false);
    handleClose();
  };

  useEffect(() => {
    if (
      open &&
      userInfo &&
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("admin") &&
      userIdToCheck
    ) {
      axios
        .get(getURLs("user-searches-by-uid"), {
          headers: {
            "auth-token": userInfo?.authToken,
          },
          params: {
            userIdToCheck,
          },
        })
        .then((res) => {
          setCurrentSearches(res.data?.userSearchesLeft);
        })
        .catch((err) => {
          setError({
            show: true,
            message: err?.message
              ? `Unable to fetech current number of searches for user due to: ${err?.message}`
              : "Unable to fetch current number of searches, please try later",
          });
          setTimeout(() => {
            setError({ show: false, message: "" });
          }, [2000]);
        });
    }
  }, [userInfo, userIdToCheck, open]);

  return (
    <CustomModal show={open} centered onHide={handleCloseModal}>
      {open && (
        <div className="w-full pb-4">
          <div className="flex items-center justify-end w-full py-4">
            <XCircleIcon
              className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
              onClick={handleCloseModal}
            />
          </div>
          {!searchesUpdated ? (
            <div className="flex flex-col space-y-4 px-4">
              <div className="space-y-0.5">
                <label className="text-sm font-inter font-normal">
                  Current searches left
                </label>
                <input
                  disabled
                  value={currentSearches}
                  className="w-full border border-gray-200 rounded-md px-2 py-2 bg-gray-300 text-gray-700 text-sm"
                />
              </div>

              <div className="space-y-0.5">
                <label className="text-sm font-inter font-normal">
                  New Searches
                </label>
                <input
                  value={newSearches}
                  onChange={(e) => setNewSearches(e.target.value)}
                  className="w-full border border-gray-200 rounded-md px-2 py-2 text-sm"
                />
              </div>

              <button
                className="bg-green3 disabled:bg-green3/50 disabled:text-gray-200 text-white text-center px-4 py-2 rounded-md self-end"
                onClick={handleSearchesModify}
                disabled={disableButton}
              >
                Update
              </button>

              {error?.show && (
                <p className="text-center text-red-500 font-normal font-inter text-sm">
                  {error?.message}
                </p>
              )}
            </div>
          ) : (
            <p className="text-sm font-semibold font-inter text-black w-full text-center block pb-5">
              Searches for user updated successfully
            </p>
          )}
        </div>
      )}
    </CustomModal>
  );
};

export default ChangeSearchesLeftForUser;
