import React, { useState } from "react";
import { PencilSquareIcon, PhotoIcon } from "@heroicons/react/24/outline";
import "../CourseDetailsSectionContent.css";
import CourseOverview from "../CourseOverview/CourseOverview";
import CourseNotes from "../CourseNotes/CourseNotes";
import CourseDetailsTabs from "../CourseDetailsTabs/CourseDetailsTabs";
import ShowContentDescription from "../ShowContentDescription/ShowContentDescription";

const DynamicCourseOverview = ({
  courseData,
  sectionContent,
  showCreateNoteButton = true,
}) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [creatingNote, setCreatingNote] = useState(false);

  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <div className="w-full mb-5 lg:mb-0 lg:basis-3/4 px-5 py-2 lg:border-l lg:border-l-gray-200 lg:dark:border-l-purple2">
      <h3 className="font-bold font-inter text-2xl md:text-3xl">
        {courseData?.name}
      </h3>

      <div className="w-full px-2 py-2 dark:bg-purple2 bg-gray42 rounded-2xl lg:rounded-none lg:bg-inherit lg:dark:bg-inherit lg:p-0 mb-4">
        {courseData?.imageUrl ? (
          <img
            src={courseData?.imageUrl}
            alt="course_img"
            className="h-[250px] max-w-2xl mx-auto w-full object-cover rounded-lg"
          />
        ) : (
          <div className="h-[250px] w-full mx-auto border border-gray-300 dark:border-purple15 dark:bg-inherit rounded-md flex items-center justify-center mt-4">
            <PhotoIcon className="h-10 w-10 text-gray-300" />
          </div>
        )}
        <div className="lg:hidden block">
          <CourseOverview
            courseData={courseData}
            sectionContent={sectionContent}
            external
          />
        </div>
      </div>

      {showCreateNoteButton && (
        <button
          className={`lg:hidden ${
            creatingNote
              ? "bg-blue-400 dark:bg-purple2 text-white dark:text-blue-400"
              : "bg-purple10 dark:bg-purple2 text-blue-400 dark:text-blue-400"
          } flex items-center space-x-2 rounded-[30px] my-4 px-4 py-2`}
          onClick={() => setCreatingNote((prev) => !prev)}
        >
          <PencilSquareIcon className="h-4 w-4" />
          <p className={`font-urbanist font-semibold text-sm`}>
            {creatingNote ? "Hide Notes" : "Create notes"}
          </p>
        </button>
      )}

      <div className="lg:hidden block">{creatingNote && <CourseNotes />}</div>

      <div className="hidden lg:block">
        <CourseDetailsTabs
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      </div>

      <div className="hidden lg:block">
        {selectedTab === 1 ? (
          <CourseOverview
            courseData={courseData}
            sectionContent={sectionContent}
            external
          />
        ) : selectedTab === 2 ? (
          <CourseNotes />
        ) : (
          <ShowContentDescription
            expanded={false}
            containerRef={null}
            content={sectionContent?.content}
            isExpandable={false}
            toggleExpand={null}
          />
        )}
      </div>
    </div>
  );
};

export default DynamicCourseOverview;
