import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/user";
import { InstructorDataContext } from "../../../context/instructorData";
import {
  deleteBlog,
  fetchUserBlogs,
  uploadBlog,
} from "../../../utils/network-requests";
import MyBlogs from "../../../components/InstructorDashboardComponents/InstructorBlogsOverview/MyBlogs/MyBlogs";
import UploadBlog from "../../../components/InstructorDashboardComponents/InstructorBlogsOverview/UploadBlog/UploadBlog";
import ImageUploadingModal from "../../../components/ImageUploadingModal";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal";
import { UploadBlogFields } from "../../../utils/staticData";
import BlogDetailsCard from "../../../components/BlogPageComponents/BlogDetailsCard/BlogDetailsCard";

const InstructorBlogsOverview = () => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    state: { instructorBlogs },
    updateBlogs,
  } = useContext(InstructorDataContext);

  const [currentBlogs, setCurrentBlogs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [error, setError] = useState({ show: false, message: "" });

  const [formData, setFormData] = useState({ ...UploadBlogFields });
  const [sections, setSections] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadBlogError, setUploadBlogError] = useState({
    show: false,
    message: "",
  });
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deletingBlogId, setDeleteBlogId] = useState("");

  const [uploadBlogActive, setUploadBlogActive] = useState(false);
  const [editingBlog, setEditingBlog] = useState(false);

  // show previe stat
  const [showBlogPreview, setShowBlogPreview] = useState(false);

  // function to handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // handler function after upload blog success
  const uploadBlogSuccess = (newBlogDetails, updatedFormData, isPublished) => {
    setIsUploading(false);
    setUploadMessage("");
    let blogExists = false;
    const updatedBlogs = instructorBlogs.map((blog) => {
      if (blog?._id === newBlogDetails?._id) {
        // Blog with same ID exists, update its content
        blogExists = true;
        return {
          ...blog,
          ...newBlogDetails,
        };
      } else {
        return blog;
      }
    });
    if (!blogExists) {
      updatedBlogs.push(newBlogDetails);
    }
    if (isPublished) {
      setUploadBlogActive(false);
    }
    updateBlogs(updatedBlogs);
    setFormData({ ...updatedFormData });
  };

  // handler function after upload blog fails
  const uploadBlogFailure = (err) => {
    setIsUploading(false);
    setUploadMessage("");
    setUploadBlogError({
      show: true,
      message: err?.response?.data?.message || err?.message,
    });
    setConfirmDeleteModal(false);
    setDeleteBlogId("");
  };

  // function to handle upload blog
  const handleBlogUpload = (content, publish) => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("instructor")
    ) {
      setIsUploading(true);
      setUploadMessage("Please wait uploading blog for review");
      const updatedFormData = { ...formData };
      updatedFormData.content = content;
      uploadBlog(updatedFormData, userInfo?.authToken, publish)
        .then((blogDetails) => {
          uploadBlogSuccess(blogDetails, updatedFormData, publish);
        })
        .catch((err) => {
          console.log(err);
          uploadBlogFailure(err?.message || "An Error Occurred");
        });
    }
  };
  // function to open confirmation modal for deleting a blog
  const handleDeleteBlog = (blogId) => {
    setConfirmDeleteModal(true);
    setDeleteBlogId(blogId);
  };

  // function to handle close delete confirmation modal
  const handleCloseDeleteModal = () => {
    setConfirmDeleteModal(false);
    setDeleteBlogId("");
  };

  // function to handler delete blog
  const handleConfirmDelete = () => {
    if (isUploading) return;
    setIsUploading(true);
    setUploadMessage("Please wait while we are deleting your blog");
    deleteBlog(deletingBlogId, userInfo?.authToken)
      .then(() => {
        const remainingBlogs = instructorBlogs?.filter(
          (blog) => blog._id !== deletingBlogId
        );
        updateBlogs(remainingBlogs);
        setIsUploading(false);
        setUploadMessage("");
        setConfirmDeleteModal(false);
        setDeleteBlogId("");
      })
      .catch((err) => {
        console.log(err);
        uploadBlogFailure(err);
      });
  };

  // function to handle editing a blog
  const handleEditButtonClick = (blogInfo) => {
    setFormData(blogInfo);
    setEditingBlog(true);
    setUploadBlogActive(true);
  };

  // function to handle preview click
  const handlePreviewClick = () => {
    setFormData({
      ...formData,
      author: formData?.author || userInfo?.name,
      authorImage: formData?.authorImage || userInfo?.profileImage || "",
      createdAt: formData?.createdAt || new Date().toISOString(),
      content: sections,
    });
    setShowBlogPreview(true);
  };

  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      userInfo?.role?.includes("instructor")
    ) {
      fetchUserBlogs(userInfo.authToken)
        .then((blogs) => {
          updateBlogs(blogs);
        })
        .catch((err) => {
          setError({ show: true, message: "Error while fetching blogs" });
        });
    }
  }, [userInfo]);

  useEffect(() => {
    const activeBlogs = instructorBlogs?.filter(
      (blog) => blog.status === "approved"
    );
    const draftBlogs = instructorBlogs?.filter(
      (blog) => blog.status === "draft"
    );
    const pendingBlogs = instructorBlogs?.filter(
      (blog) => blog.status === "pending"
    );
    if (selectedTab === 1) {
      setCurrentBlogs(activeBlogs);
    } else if (selectedTab === 2) {
      setCurrentBlogs(draftBlogs);
    } else {
      setCurrentBlogs(pendingBlogs);
    }
  }, [instructorBlogs, selectedTab]);

  return (
    <div className="px-4 py-2">
      {(showBlogPreview || uploadBlogActive) && (
        <p
          className="font-urbanist font-medium text-gray-400 text-sm block mb-4 cursor-pointer"
          onClick={() => {
            if (showBlogPreview) {
              setShowBlogPreview(false);
            } else {
              setUploadBlogActive(false);
              setEditingBlog(false);
              setFormData({ ...UploadBlogFields });
            }
          }}
        >
          {`Blogs > `}&nbsp;
          <span
            className=""
            style={{
              backgroundImage:
                "linear-gradient(0deg, #2898FF, #2898FF), linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {showBlogPreview ? "Preview" : "Upload"}
          </span>
        </p>
      )}
      <div className="bg-white dark:bg-purple14 border border-gray-100 dark:border-purple25 rounded-lg">
        {showBlogPreview ? (
          // Blog Details Section
          <BlogDetailsCard blogDetails={formData} hideSocialLink />
        ) : uploadBlogActive ? (
          <UploadBlog
            formData={formData}
            handleChange={handleChange}
            handleUpload={handleBlogUpload}
            disableUploadButton={isUploading}
            isEditing={editingBlog}
            handlePreviewClick={handlePreviewClick}
            sections={sections}
            setSections={setSections}
          />
        ) : (
          <MyBlogs
            currentBlogs={currentBlogs}
            error={error}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setUploadBlogActive={setUploadBlogActive}
            handleDeleteBlog={handleDeleteBlog}
            handleEditButtonClick={handleEditButtonClick}
          />
        )}
      </div>

      <ImageUploadingModal modalOpen={isUploading} message={uploadMessage} />
      <CustomModal
        show={uploadBlogError.show}
        onHide={() => setUploadBlogError({ show: false, message: "" })}
      >
        {uploadBlogError.show && (
          <>
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Error
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={() => setUploadBlogError({ show: false, message: "" })}
              />
            </div>
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <p className="font-urbanist text-red-500 text-sm font-medium">
                {uploadBlogError?.message}
              </p>
            </div>
          </>
        )}
      </CustomModal>
      <ConfirmDeleteModal
        closeModal={handleCloseDeleteModal}
        handleConfirm={handleConfirmDelete}
        message={"Are you sure you want to delete this blog ?"}
        showModal={confirmDeleteModal}
      />
    </div>
  );
};

export default InstructorBlogsOverview;
