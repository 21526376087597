import React, { useState } from "react";
import ImageUpload from "../ImageUpload";

const AddFileHandler = ({
  handleFileChange,
  acceptedTypes,
  uploadComponentTitle,
  inputId,
  multiple = false,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [draggingOver, setDraggingOver] = useState(false);

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange({ target: { files: [e.dataTransfer.files] } });
    }
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setDraggingOver(null);
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
    setDraggingOver(true);
  }

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  return (
    <div>
      <input
        type="file"
        id={inputId}
        hidden
        multiple={multiple}
        accept=".csv"
        onChange={handleFileChange}
        onClick={(event) => {
          event.target.value = null;
        }}
      />

      <label
        htmlFor={inputId}
        className="flex flex-col md:flex-row md:items-center space-x-0 space-y-2 md:space-y-0 md:space-x-2 w-full"
      >
        <div
          className="basis-1/2 w-full"
          onDragEnter={handleDragEnter}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
        >
          <ImageUpload
            acceptedTypes={uploadComponentTitle}
            imageDragActive={dragActive && draggingOver}
          />
        </div>
      </label>
    </div>
  );
};

export default AddFileHandler;
