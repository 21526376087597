import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalCourseButtonRender from "./PaypalCourseButtonRenderer/PaypalCourseButtonRenderer";
import PaymentSuccessfulModal from "../../modal/PaymentSuccessfulModal";
import axios from "axios";
import { UserContext } from "../../context/user";
import { getURLs } from "../../urlConfig";
import { getSuccessAndErrorUrlForCoursePayments } from "../../utils/utils";

const CoursePaypalCheckout = ({ amount, courseId, courseSlug }) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);

  // navigate state
  const navigate = useNavigate();
  // initial options for paypal
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };
  // error state
  const [error, setError] = useState("");

  // payment sucessful modal state
  const [showModal, setShowModal] = useState(false);

  const handleClosePopup = () => {
    navigate(`/course-details/${courseSlug}`, { replace: true });
    setShowModal(false);
  };

  // custom handler func. to check whether the payment is successful or not
  const onApprove = async (data) => {
    // Order is captured on the server and the response is returned to the browser
    return axios
      .post(
        `${getURLs("paypal-checkout")}/course/capture-paypal-order`,
        {
          orderID: data?.orderID,
          student_id: userInfo?._id,
          course: courseId,
        },
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowModal(true);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 ||
          err?.response?.status === 401 ||
          err?.response?.status === 500
        )
          setError(err?.response?.data?.message);
        console.log(err);
      });
  };

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        {/* custom modal as paypal button state hook can only be used inside PaypalScriptProvider */}
        {amount && courseId && Object.keys(userInfo)?.length && (
          <PaypalCourseButtonRender
            error={error}
            setError={setError}
            onApprove={onApprove}
            courseId={courseId}
            successUrl={
              getSuccessAndErrorUrlForCoursePayments(courseSlug).successUrl
            }
            errorUrl={
              getSuccessAndErrorUrlForCoursePayments(courseSlug).errorUrl
            }
          />
        )}
      </PayPalScriptProvider>
      {showModal && (
        <PaymentSuccessfulModal handleClosePopup={handleClosePopup} />
      )}
    </>
  );
};

export default CoursePaypalCheckout;
