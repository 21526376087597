const devQueryUrl = `http://54.81.171.95:5010/query`;
const prodQueryUrl = `https://api.vaisage.com/query`;

const server =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? `http://localhost:8081`
    : // : "https://brainjee-main-server.vercel.app"
      "https://apidev.brainjee.com";

let currentUrl;

const URLs = {
  appInfo: `${server}/api/appData/appInfo`,
  "fetch-page-data": `${server}/api/appData/fetch-page-data`,
  loginUrl: `${server}/api/auth/login`,
  logoutUrl: `${server}/api/auth/logout`,
  signupUrl: `${server}/api/auth/signup`,
  "verify-email": `${server}/api/auth/verify-email`,
  "forgot-password": `${server}/api/auth/forgot-password`,
  "reset-password": `${server}/api/auth/set-new-password`,
  "resend-otp": `${server}/api/auth/resend-otp`,
  "verify-otp": `${server}/api/auth/verify-otp`,
  "set-subscription": `${server}/api/auth/set-subscription`,
  getGoogleLoginUrl: `${server}/api/auth/google-login`,
  "apple-login": `${server}/api/auth/apple-login`,
  getFacebookLoginUrl: `${server}/api/auth//facebook-login`,
  getUserDetails: `${server}/api/auth/getuser`,
  "update-information": `${server}/api/auth/update-info`,
  "refresh-user": `${server}/api/auth/refresh`,
  "searches-left": `${server}/api/free/get-user-searches`,
  "set-searches": `${server}/api/free/decrease-user-searches`,
  "subscribe-newsletter": `${server}/api/subscribe/newsletter`,
  "contact-form": `${server}/api/contact/submit-form`,
  "add-chat": `${server}/api/chats/add-chat`,
  "get-chat": `${server}/api/chats/get-chat`,
  "image-upload-url": `${server}/api/image/get-upload-url`,
  // search history route:
  "user-search-history": `${server}/api/search/get-search-history`,
  "add-search": `${server}/api/search/add-search`,
  "all-search-history": `${server}/api/search/all-search-history`,
  // checkout
  checkout: `${server}/api/checkout`,
  "stripe-checkout": `${server}/api/checkout/stripe-checkout`,
  "paypal-checkout": `${server}/api/checkout/paypal-checkout`,
  "cancel-subscription": `${server}/api/checkout/cancel-subscription`,
  // instrutor api routes
  "add-course": `${server}/api/instructor/add-course`,
  "edit-course": `${server}/api/instructor/update-course`,
  "edit-external-course": `${server}/api/application-study-material/update-course`,
  "add-quiz": `${server}/api/instructor/add-quiz`,
  "get-instructor-info": `${server}/api/instructor/get-instructor-info`,
  "get-instructor-quizes": `${server}/api/instructor/get-quizes`,
  "get-instructor-courses": `${server}/api/instructor/get-courses`,
  "delete-instructor-course": `${server}/api/instructor/delete-course`,
  "delete-instructor-quiz": `${server}/api/instructor/delete-quiz`,
  "delete-quiz-question": `${server}/api/instructor/delete-quiz-question`,
  "get-quiz": `${server}/api/instructor/get-single-quiz`,
  "edit-quiz-question": `${server}/api/instructor/edit-quiz-question`,
  // enrollments
  "is-enrolled": `${server}/api/enrollments/enrolled-user`,
  enroll: `${server}/api/enrollments/enroll-in-course`,
  "get-instructor-students": `${server}/api/enrollments/get-students`,
  "user-enrolled-courses": `${server}/api/enrollments/user-enrolled-courses`,
  // orders
  "fetch-orders": `${server}/api/orders/fetch-orders`,
  "order-stats": `${server}/api/orders/order-stats`,
  // allcourse and quiz route
  "get-all-courses": `${server}/api/application-study-material/all-courses`,
  "get-all-quizes": `${server}/api/application-study-material/all-quizes`,
  // student routes
  "submit-quiz": `${server}/api/student/evaluate-quiz`,
  "get-attempted-quiz": `${server}/api/student/get-attempted-quiz`,
  // admin route
  "change-status": `${server}/api/admin/status-change`,
  "instructor-accounts": `${server}/api/admin/all-instructor-accounts`,
  "all-accounts": `${server}/api/admin/all-accounts`,
  "update-role": `${server}/api/admin/update-role`,
  "delete-account": `${server}/api/admin/delete-user`,
  "upload-course-through-csv": `${server}/api/admin/upload-course-csv`,
  "check-task-status": `${server}/api/admin/check-task-status`,
  "user-searches-by-uid": `${server}/api/admin/user-searches-by-uid`,
  "update-user-searches": `${server}/api/admin/update-user-searches`,
  "paid-accounts": `${server}/api/admin/paid-accounts`,
  "admin-filters": `${server}/api/admin/admin-filters`,
  // bot info
  "get-bot-info": `${server}/api/bot-info/get-bot-info`,
  "set-bot-info": `${server}/api/bot-info/set-bot-info`,
  "set-bot-chat-id": `${server}/api/bot-info/set-bot-chat-id`,
  // books routes
  "all-books": `${server}/api/books/all-books`,
  "book-info": `${server}/api/books/book-info`,
  "add-new-book": `${server}/api/books/add-new-book`,
  "update-book": `${server}/api/books/edit-book`,
  "delete-book": `${server}/api/books/delete-book`,
  "all-books-name": `${server}/api/books/get-books-name`,
  "set-book-details": `${server}/api/books/set-book-details`,
  "update-bookmarked-courses": `${server}/api/books/update-bookmarked-courses`,
  "add-course-csv": `${server}/api/books/upload-dynamic-course`,
  "all-dynamic-books": `${server}/api/books/all-dynamic-books`,
  "dynamic-book-info": `${server}/api/books/dynamic-book-info`,
  // blogs routes
  "fetch-blogs": `${server}/api/blogs/all-blogs`,
  "fetch-blog": `${server}/api/blogs/blog`,
  "fetch-user-blogs": `${server}/api/blogs/fetch-user-blogs`,
  "upload-blog": `${server}/api/blogs/upload-blog`,
  "delete-blog": `${server}/api/blogs/delete-blog`,
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  currentUrl = devQueryUrl;
} else {
  // production code
  currentUrl = prodQueryUrl;
}

const BOTURLS = {
  bot_id: ({ botName }) => `https://api${botName}dev.brainjee.com/get_bot_id`,
  text_chat_id: ({ botName, botId }) =>
    `https://api${botName}dev.brainjee.com/new_chat/${botId}`,
  vision_chat_id: ({ botName, botId }) =>
    `https://api${botName}dev.brainjee.com/vision_new_chat/${botId}`,
  new_text: ({ botName, botId, chatId, query, includeImageVideos = true }) =>
    `https://api${botName}dev.brainjee.com/response/${botId}/${chatId}?query=${query}&search=${includeImageVideos}`,
  new_vision: ({ botName, botId, visionId, includeImageVideos = true }) =>
    `https://api${botName}dev.brainjee.com/vision_response/${botId}/${visionId}&search=${includeImageVideos}`,
  new_voice: ({ botName, botId, chatId, includeImageVideos = true }) =>
    `https://api${botName}dev.brainjee.com/audio_response/${botId}/${chatId}&search=${includeImageVideos}`,
  text: ({ botName }) =>
    `https://api${botName}dev.brainjee.com/text_${botName}bot/`,
  vision: ({ botName }) =>
    `https://api${botName}dev.brainjee.com/vision_${botName}bot/`,
  voice: ({ botName }) =>
    `https://api${botName}dev.brainjee.com/voice_${botName}bot/`,
  update: ({ botName }) =>
    `https://api${botName}dev.brainjee.com/update_${botName}bot/`,
};

export const getURLs = (urlName) => URLs[urlName];
export const getBotURLs = (searchFor, params) => BOTURLS[searchFor](params);

export { currentUrl };
