import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { AppDataContext } from "../../../context/appData";
import { UserContext } from "../../../context/user";
import useDebounce from "../../../hooks/useDebounce";
import { getURLs } from "../../../urlConfig";
import { fetchPageData } from "../../../utils/network-requests";
import CourseCard from "../../../components/CourseCard";

const UserShowDynamicCourses = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const {
    state: { userInfo, bookmarkedCourses },
  } = useContext(UserContext);

  const [coursesData, setCoursesData] = useState([]);
  // page no state
  const [page, setPage] = useState(1);
  // more blogs available state
  const [hasMore, setHasMore] = useState(false);
  // first response came state
  const [firstResponseCame, setFirstResponseCame] = useState(false);
  // loading state
  const [loading, setLoading] = useState(false);
  // search state
  const [searchInput, setSearchInput] = useState("");
  const [debouncedInput, setDebouncedInput] = useState("");
  // grade input
  const [selectedGrade, setSelectedGrade] = useState("");
  // subject input
  const [selectedSubject, setSelectedSubject] = useState("");

  const { debounce } = useDebounce();

  const handleSearch = useCallback(
    debounce((searchVal) => {
      handleReset();
      setDebouncedInput(searchVal);
    }, 500),
    []
  );

  // handler func. for load more blogs
  const handleLoadMore = () => {
    setPage((currPage) => currPage + 1);
  };

  // handler func for resetting states
  const handleReset = () => {
    // resetting the states
    setPage(1);
    setHasMore(false);
    setCoursesData([]);
  };

  // useeffect to fetch data whenever we change tab, page and search value
  useEffect(() => {
    if (Object.keys(userInfo || {}).length > 0) {
      setLoading(true);
      axios
        .get(getURLs("all-dynamic-books"), {
          params: {
            page,
            search: debouncedInput,
            grade: selectedGrade,
          },
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
        })
        .then((res) => {
          if (res.data?.next) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }

          setCoursesData([...coursesData, ...res.data?.AllCoursesData]);
          setFirstResponseCame(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [userInfo, page, debouncedInput, selectedGrade]);

  useEffect(() => {
    if (!Object.keys(appData?.instructorCreateCoursePage || {}).length > 0) {
      fetchPageData("instructorCreateCoursePage")
        .then((pageData) => {
          initializeAppData({ instructorCreateCoursePage: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appData.instructorCreateCoursePage]);

  return (
    <div className="dark:bg-darkBgColor1 px-4 py-5 space-y-5">
      {/* header */}
      <div className="flex items-center justify-end space-x-2 lg:basis-3/4 w-full">
        <div className="flex dark:bg-purple2 bg-gray9 rounded-md px-2 items-center flex-1">
          <input
            placeholder="Search Courses"
            className="bg-inherit flex-1 w-full py-3 text-sm outline-none border-none placeholder:text-gray30 text-gray30 font-urbanist font-medium"
            onChange={(e) => {
              setSearchInput(e.target.value);
              handleSearch(e.target.value);
            }}
            value={searchInput}
          />

          <MagnifyingGlassIcon className="text-gray30 w-5 h-5" />
        </div>

        <div className="flex items-center space-x-4">
          <div className="dark:bg-purple2 bg-gray9 rounded-md px-4 py-3 flex items-center font-urbanist text-sm font-semibold text-black dark:text-white">
            <select
              className="bg-transparent w-full outline-none border-none"
              // defaultValue={formData?.grades[0]?.name}
              value={selectedGrade || ""}
              onChange={(e) => {
                handleReset();
                setSelectedGrade(e.target.value);
              }}
            >
              <option value={""}>Select Grade</option>
              {appData?.instructorCreateCoursePage?.addCourseFormData?.availableGradesData?.map(
                (grade) => (
                  <option key={grade.value} value={grade.value}>
                    {grade.name}
                  </option>
                )
              )}
            </select>
          </div>

          {/* <div className="dark:bg-purple2 bg-gray9 rounded-md px-4 py-3 flex items-center font-urbanist text-sm font-semibold text-black dark:text-white">
            Select Subject
            <ChevronDownIcon className="dark:text-white text-black w-4 h-4 ml-2" />
          </div> */}
        </div>
      </div>

      {/* courses */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {coursesData?.map(
          (courseData) =>
            courseData?.status === "approved" && (
              <CourseCard
                key={courseData?._id}
                data={courseData}
                courseBookmarked={bookmarkedCourses?.some(
                  (course) => course.toString() === courseData._id.toString()
                )}
                dynamic={true}
              />
            )
        )}
      </div>

      {loading && (
        <p className="text-center text-black1 dark:text-white font-urbanist font-medium text-sm block py-4 px-4">
          Loading...
        </p>
      )}

      {firstResponseCame && hasMore && (
        <div className="flex items-center justify-center z-10">
          <button
            className="rounded-lg border-none text-white text-sm md:text-base font-raleway font-bold w-max px-10 py-2"
            style={{
              background: loading
                ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%)"
                : "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
            }}
            disabled={loading}
            onClick={handleLoadMore}
          >
            {loading ? "Loading..." : "Read More"}
          </button>
        </div>
      )}

      {/* if there are no respones and not loading */}
      {!loading && coursesData?.length === 0 && (
        <h4 className="font-semibold font-urbanist text-center w-full text-lg text-black1 dark:text-white">
          No Courses Available
        </h4>
      )}
    </div>
  );
};

export default UserShowDynamicCourses;
