import axios from "axios";
import { getBotURLs, getURLs } from "../urlConfig";

export const fetchPageData = (pageName) => {
  return new Promise((resolve, reject) => {
    axios
      .post(getURLs("fetch-page-data"), {
        pageName,
      })
      .then((res) => {
        resolve(res.data.pageData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get botids
export const getBotIds = async (tutor) => {
  return new Promise((resolve, reject) => {
    axios
      .get(getBotURLs("bot_id", { botName: tutor }))
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data?.bot_id);
        }
      })
      .catch((err) => {
        resolve("");
      });
  });
};

// get text chatid's
export const getTextChatIds = async (tutor, botId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(getBotURLs("text_chat_id", { botName: tutor, botId }))
      .then((res) => {
        if (res.status === 200 && res.data?.chat_id) {
          resolve(res.data?.chat_id);
        } else {
          reject({ message: "Chat Id is null" });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get text chatid's
export const getVisionChatIds = async (tutor, botId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(getBotURLs("vision_chat_id", { botName: tutor, botId }))
      .then((res) => {
        if (res.status === 200 && res.data?.vision_id) {
          resolve(res.data?.vision_id);
        } else {
          reject({ message: "Vision Id is null" });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// set bot ids
export const setBotIds = async (botIdsInfo, authToken) => {
  // axios
  //   .post(
  //     getURLs("set-bot-info"),
  //     { botIdsInfo: botIdsInfo },
  //     {
  //       headers: {
  //         "auth-token": authToken,
  //       },
  //       withCredentials: true,
  //     }
  //   )
  //   .then((res) => {})
  //   .catch((err) => {
  //     console.log(err);
  //   });

  const userBotInfo = sessionStorage.getItem("botInfo");
  if (userBotInfo) {
    const botInfo = JSON.parse(userBotInfo);
    const newBotInfo = {
      ...botInfo,
      botIds: {
        ...botInfo?.botIds,
        ...botIdsInfo,
      },
    };
    sessionStorage.setItem("botInfo", JSON.stringify(newBotInfo));
  } else {
    const botInfo = {
      botIds: { ...botIdsInfo },
    };
    sessionStorage.setItem("botInfo", JSON.stringify(botInfo));
  }
};

// set chatid's
export const setChatIds = async (chatIds, authToken) => {
  // return new Promise((resolve, reject) => {
  //   axios
  //     .post(
  //       getURLs("set-bot-chat-id"),
  //       {
  //         chatIdsInfo: chatIds,
  //       },
  //       {
  //         headers: {
  //           "auth-token": authToken,
  //         },
  //         withCredentials: true,
  //       }
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         resolve(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       reject(err);
  //     });
  // });
  const userBotInfo = sessionStorage.getItem("botInfo");
  if (userBotInfo) {
    const botInfo = JSON.parse(userBotInfo);
    const newBotInfo = {
      ...botInfo,
      botChatIds: {
        ...botInfo?.botChatIds,
        ...chatIds,
      },
    };
    sessionStorage.setItem("botInfo", JSON.stringify(newBotInfo));
  }
};

// function to save search history in DB
export const saveSearchHistory = (searchObject, authToken) => {
  return new Promise((resolve, reject) => {
    const { searchTerm, searchBot } = searchObject;
    if (!searchTerm || !searchBot) return;
    axios
      .post(
        getURLs("add-search"),
        {
          searchTerm,
          searchBot,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

//function to save chat history to db
export const saveChat = (tutor, chatObj, authToken) => {
  axios
    .post(
      getURLs("add-chat"),
      {
        tutor: tutor,
        question: chatObj.question,
        answer: chatObj.answer,
      },
      {
        headers: {
          "auth-token": authToken,
        },
        withCredentials: true,
      }
    )
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
};

export const updateCourse = (formData, authToken, publish = false) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        getURLs("update-book"),
        { updatedCourseData: formData, publishing: publish },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const fetchUserBlogs = (authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(getURLs("fetch-user-blogs"), {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        resolve(res.data?.allBlogs);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const uploadImage = (imageFile, imageFor, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getURLs("image-upload-url"),
        { imageFor },
        {
          headers: { "auth-token": authToken },
          withCredentials: true,
        }
      )
      .then((signedUrl) => {
        fetch(signedUrl?.data?.url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: imageFile,
        })
          .then(() => {
            resolve(signedUrl.data?.url?.split("?")[0]);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const uploadBlog = async (blogData, authToken, isPublishing) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getURLs("upload-blog"),
        { blogDetails: { ...blogData }, publish: isPublishing },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((resp) => {
        resolve(resp.data?.blogDetails);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteBlog = (deleteBlogId, authToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(getURLs("delete-blog"), {
        withCredentials: true,
        headers: {
          "auth-token": authToken,
        },
        params: { blogId: deleteBlogId },
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const enrollInCourse = (authToken, courseId, studentInfo, authorId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        getURLs("enroll"),
        {
          courseId,
          studentName: studentInfo.name,
          studentEmail: studentInfo?.email,
          studentProfilePhoto: studentInfo?.image,
          studentCountry: studentInfo?.country,
          authorId,
        },
        {
          headers: {
            "auth-token": authToken,
          },
          withCredentials: true,
        }
      )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllInstructors = (authToken, page) => {
  return new Promise((resolve, reject) => {
    axios
      .get(getURLs("instructor-accounts"), {
        withCredentials: true,
        params: {
          page,
        },
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleBookmarkCourse = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        getURLs("update-bookmarked-courses"),
        {
          activity: data.activity,
          courseId: data.courseId,
        },
        {
          headers: {
            "auth-token": data.authToken,
          },
        }
      )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        console.log(err);
        reject();
      });
  });
};
