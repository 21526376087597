import React, { useContext, useEffect, useState } from "react";
import CourseInfoHeader from "../components/CourseInfoHeader";
import CreateCourseSidebar from "../components/CreateCourseComponents/CreateCourseSidebar/CreateCourseSidebar";
import AddCourseInformation from "../components/CreateCourseComponents/AddCourseInformation/AddCourseInformation";
import AddCourseSectionInformation from "../components/CreateCourseComponents/AddCourseSectionInformation/AddCourseSectionInformation";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../context/user";
import { getURLs } from "../urlConfig";
import { CourseFields } from "../utils/staticData";
import { fetchPageData, updateCourse } from "../utils/network-requests";
import ImageUploadingModal from "../components/ImageUploadingModal";
import CustomModal from "../components/CustomModal/CustomModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import CourseDetailsSectionContent from "../components/CourseDetailsSectionContent";
import { AppDataContext } from "../context/appData";
import ConfirmCourseSubmitModal from "../components/CreateCourseComponents/ConfirmCourseSubmitModal/ConfirmCourseSubmitModal";

const UpdateCourse = () => {
  const {
    state: { appData },
    initializeAppData,
  } = useContext(AppDataContext);

  const {
    state: { userInfo },
  } = useContext(UserContext);

  const navigate = useNavigate();

  const [step, setStep] = useState(3);
  const { courseId } = useParams();
  const [formData, setFormData] = useState({
    ...CourseFields,
  });

  const [expanded, setExpanded] = useState(
    formData?.grades[0]?.chapters?.[0]?._id
  );
  const [sectionContent, setSectionContent] = useState(
    formData?.grades[0]?.chapters?.[0]?.headings[0]?.topics[0]
  );

  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [error, setError] = useState({ show: false, message: "" });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [videoFiles, setVideoFiles] = useState({});
  const [imageFile, setImageFile] = useState();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      // if (JSON.stringify(prevValues) !== JSON.stringify(formData)) {
      setLoading(true);
      setModalMessage("Saving Progress...");

      let signedUrlResponses = {};
      let imageUrl;
      // upload images
      if (imageFile) {
        const imageSignedUrl = await axios.post(
          getURLs("image-upload-url"),
          { imageFor: "courseImage" },
          {
            headers: { "auth-token": userInfo?.authToken },
            withCredentials: true,
          }
        );
        await fetch(imageSignedUrl?.data?.url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: imageFile,
        });

        imageUrl = imageSignedUrl?.data?.url?.split("?")[0];
      }

      // Upload video files concurrently
      if (Object.keys(videoFiles).length > 0) {
        const signedUrlPromises = Object.entries(videoFiles).map(
          async ([key, videoData]) => {
            const { file } = videoData;
            const signedUrlResponse = await axios.post(
              getURLs("image-upload-url"),
              { imageFor: "topicVideo" },
              {
                headers: { "auth-token": userInfo?.authToken },
                withCredentials: true,
              }
            );
            const signedUrl = signedUrlResponse?.data?.url;

            // Upload video file to S3 using the signed URL
            await fetch(signedUrl, {
              method: "PUT",
              headers: {
                "Content-Type": "video/*",
              },
              body: file,
            });

            // Store the uploaded file URL (excluding the query string)
            signedUrlResponses[key] = signedUrl.split("?")[0];
          }
        );
        await Promise.all(signedUrlPromises);
      }

      const updatedFormData = { ...formData };

      // adding image url to formdata
      if (imageUrl) {
        updatedFormData.imageUrl = imageUrl;
      }

      if (Object.keys(videoFiles).length > 0) {
        // Update formData with video links
        Object.entries(videoFiles).forEach(
          ([key, { chapterIndex, headingIndex, topicIndex }]) => {
            const videoUrl = signedUrlResponses[key];
            updatedFormData.grades[0].chapters[chapterIndex].headings[
              headingIndex
            ].topics[topicIndex].video_link = videoUrl;
          }
        );
      }

      updateCourse(updatedFormData, userInfo?.authToken)
        .then((response) => {
          const { savedBookDetails } = response.data;
          setLoading(false);
          setModalMessage("");
          setFormData({
            ...savedBookDetails,
            title: savedBookDetails?.name,
            category: savedBookDetails?.course_category,
            level: savedBookDetails?.course_level,
          });
          setVideoFiles({});
        })
        .catch((err) => {
          setLoading(false);
          setModalMessage("");
          console.log(err);
          setError({
            show: true,
            message: err?.response?.data?.message || err?.message,
          });
        });
      // }
    } catch (error) {
      console.log(error);
      setError({ show: true, message: error?.message });
      setLoading(false);
      setModalMessage("");
    }
  };

  useEffect(() => {
    if (
      Object.keys(userInfo || {}).length > 0 &&
      (userInfo?.role?.includes("instructor") ||
        userInfo?.role?.includes("admin"))
    ) {
      setLoading(true);
      setModalMessage("Please wait loading book data");
      // fetch course information from course id
      axios
        .get(getURLs("book-info"), {
          headers: {
            "auth-token": userInfo.authToken,
          },
          params: { bookSlug: courseId },
        })
        .then((response) => {
          const bookInfo = response.data?.bookDetails;
          setFormData({
            ...bookInfo,
            title: bookInfo?.name,
            category: bookInfo?.course_category,
            level: bookInfo?.course_level,
          });
          setLoading(false);
          setModalMessage("");
        })
        .catch((err) => {
          setLoading(false);
          setModalMessage("");
          console.log(err);
          setError({
            show: true,
            message: err?.response?.data?.message || err?.message,
          });
        });
    }
  }, [userInfo, courseId]);

  useEffect(() => {
    setExpanded(formData?.grades[0]?.chapters?.[0]?._id);
    setSectionContent(
      formData?.grades[0]?.chapters?.[0]?.headings[0]?.topics[0]
    );
  }, [formData]);

  useEffect(() => {
    if (!Object.keys(appData?.instructorCreateCoursePage || {}).length > 0) {
      fetchPageData("instructorCreateCoursePage")
        .then((pageData) => {
          initializeAppData({ instructorCreateCoursePage: pageData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appData.instructorCreateCoursePage]);

  const handleCoursePublish = () => {
    setLoading(true);
    setModalMessage("Please wait submitting course for review...");
    const updatedFormData = { ...formData };
    setFormData(updatedFormData);
    updateCourse(updatedFormData, userInfo?.authToken, true)
      .then((response) => {
        setLoading(false);
        setModalMessage("");
        setShowConfirmationModal(true);
      })
      .catch((err) => {
        setLoading(false);
        setModalMessage("");
        console.log(err);
        setShowConfirmationModal(false);
        setError({
          show: true,
          message: err?.response?.data?.message || err?.message,
        });
      });
  };

  return (
    <div className="dark:bg-darkBgColor1">
      <CourseInfoHeader
        title={formData.title}
        showSaveButton
        handleSave={handleSave}
      />

      <div className="px-4 py-5">
        <div className="flex flex-col lg:flex-row rounded-lg border border-gray39 dark:border-purple26 min-h-screen overflow-y-auto h-full dark:bg-purple14">
          <div className="w-full lg:basis-1/4">
            {/* sidebar */}
            <CreateCourseSidebar
              step={step}
              setStep={setStep}
              courseData={formData}
              expanded={expanded}
              sectionContent={sectionContent}
              setExpanded={setExpanded}
              setSectionContent={setSectionContent}
              handleConfirmPublish={handleCoursePublish}
              publishButtonDisable={loading}
            />
          </div>
          {/* content based on current step */}
          {step === 3 && (
            <AddCourseInformation
              formData={formData}
              setFormData={setFormData}
              handleChange={handleInputChange}
              handleSave={handleSave}
              availableGradesData={
                appData?.instructorCreateCoursePage?.addCourseFormData
                  ?.availableGradesData
              }
              setError={setError}
              setImageFile={setImageFile}
            />
          )}
          {step === 4 && (
            <AddCourseSectionInformation
              formData={formData}
              setFormData={setFormData}
              handleSave={handleSave}
              setVideoFiles={setVideoFiles}
            />
          )}

          {step === 5 && (
            <CourseDetailsSectionContent
              courseData={formData}
              sectionContent={sectionContent}
              showCreateNoteButton={false}
            />
          )}
        </div>
      </div>

      <ImageUploadingModal modalOpen={loading} message={modalMessage} />
      <CustomModal
        show={error.show}
        onHide={() => setError({ show: false, message: "" })}
      >
        {error.show && (
          <>
            <div className="flex items-center justify-between border-b w-full py-4">
              <h4 className="text-base font-inter font-medium text-black pl-5">
                Error updating progress
              </h4>
              <XCircleIcon
                className="text-gray-400 h-6 w-6 mr-4 cursor-pointer"
                onClick={() => setError({ show: false, message: "" })}
              />
            </div>
            <div className="w-full flex flex-col px-6 pt-5 pb-2">
              <p className="font-urbanist text-red-500 text-sm font-medium">
                {error?.message}
              </p>
            </div>
          </>
        )}
      </CustomModal>

      <ConfirmCourseSubmitModal
        show={showConfirmationModal}
        close={() => {
          setShowConfirmationModal(false);
          navigate(-1);
        }}
        courseTitle={formData?.title}
        heading={
          appData?.instructorCreateCoursePage?.publishSuccessSectionData
            ?.heading
        }
        imgUrl={
          appData?.instructorCreateCoursePage?.publishSuccessSectionData
            ?.sectionImg
        }
      />
    </div>
  );
};

export default UpdateCourse;
