import React, { useState } from "react";
import axios from "axios";
import {
  getStripe,
  getSuccessAndErrorUrlForSubscriptionPayments,
} from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { getURLs } from "../../urlConfig";

const SubscriptionStripeCheckout = ({
  userInfo,
  planId,
  subscriptionTimeline,
  planName,
  closePopupHandler,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // handle pay click
  const handlePaymentClick = async () => {
    if (userInfo && Object.keys(userInfo || {}).length > 0) {
      setLoading(true);
      const stripeClient = await getStripe();
      const { successUrl, errorUrl } =
        getSuccessAndErrorUrlForSubscriptionPayments();
      // session id of the checkout
      const response = await axios.get(
        `${getURLs("stripe-checkout")}/subscription/create-session`,
        {
          withCredentials: true,
          headers: {
            "auth-token": userInfo?.authToken,
          },
          params: {
            planId,
            subscriptionTimeline,
            planName,
            successUrl,
            errorUrl,
          },
        }
      );

      await stripeClient?.redirectToCheckout({
        sessionId: response?.data?.sessionId,
      });
      closePopupHandler();
    } else {
      navigate("/login");
    }
  };

  return (
    <button
      className="rounded-lg border-none disabled:bg-blue-300 text-white text-sm md:text-base font-urbanist font-bold w-full text-center px-10 py-2 mb-5"
      style={{
        background:
          "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF",
      }}
      disabled={loading}
      onClick={handlePaymentClick}
    >
      {loading ? "Loading..." : "Proceed to checkout"}
    </button>
  );
};

export default SubscriptionStripeCheckout;
