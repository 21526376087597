import React from "react";

const SidebarPromotionBox = () => {
  return (
    <div className="h-28 mb-1">
      <div className="bg-purple15/10 dark:border dark:border-purple15 px-4 py-4 rounded-lg h-full flex items-center justify-center">
        <p className="font-urbanist font-semibold text-black1 dark:text-white text-center mb-2">
          Ad Content
        </p>
      </div>
    </div>
  );
};

export default SidebarPromotionBox;
